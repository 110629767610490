.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bottom-right-images {
  position: relative;
  max-width: 800px;
}
.thumbnail {
  max-width: 230px;
}
.thumbnail  img{
  width: 100%;
}

/* home page booknow guest dropdown */

.guest-dropdown {
  width: 243px;
  border: 0 !important;
  padding: 0 !important; 
  box-shadow: rgba(72, 72, 72, 0.2) 0px 15px 20px;
}

.guest-dropdown-item {
  text-align: center;
  padding: 35px 10px 10px;
}

.input-arrow::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-arrow[type=number] {
  -moz-appearance: textfield;
}
.thumb-wrap {
  /* position: absolute;
  right: 0;
  opacity: 1; */
  transition: opacity 2s, transform 2s ease-out;
}
div#moveableDiv .thumb-wrap {
  position: absolute;
  /* z-index: 1111; */
  bottom: 0;
  margin-left: 500px;
  width: 230px;}
  #moveableDiv{
    height: 260px;
}
.ulheight{
  max-height: 235px;
  overflow: hidden;
}

.cursor-pointer{
  cursor: pointer;
}


/* .logo-fade-animation {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.ozen-logo-wrapper {
  background-color: #323E48;
}


.logo-overlay-wrapper {
  position: relative;
  overflow: hidden;
}

.logo-overlay-wrapper img {
  width: 300px;
}

.logo-overlay-wrapper .overlay::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(50,62,72);
      animation: reveal 2s ease infinite;
}

.logo-overlay-wrapper .white-overlay::before {
  background-color: rgb(255,255,255);
}

.logo-wrapper {
  height: 500px;
  width: 500px;
  background-position: center;
  background-size: 285px;
  background-repeat: no-repeat;
  background-image: url(../public/raaya-loader-logo.svg);
}


@keyframes reveal {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  
  50% {
    opacity: 1;
  }
  
  100%{
    transform: translate(100%);
    opacity: 0;
  }
} */
.b__parse p{
  display: inline;
}
.offer-para a{
  color: var(--fiord);
}