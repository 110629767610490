:root {
  --theme-brown: #549dae;
  --dark-brown: #6496a7;
  --light-brown: #97cadb;
  --faded-brown: #f3f8f9;
  --dark-theme: #1c4451;
  --breaker-bay: #6d462c;
  --light-see-green: #53a5a0;
  --moody-blue: #7281d0;
  --fountain-blue: #58b7cd;
  --bean-red: #fe5e68;
  --tab-hover-bg: #9ec7d3;
  --glaucous: #6188b6;
  --green: #7ca986;
  --dim-gray: #606060;
  --green-border: #bdcfcd;
  --gondola: #333333;
  --fiord: #556271;
  --medium-wood: #84b2c1;
  --glacier: #85b1bb;
  --rose: #75becf;
  --fern: #b07060;
  --tallow: #ac9f75;
  --chatelle: #aaa3af;
  --fire-brick: #a0361d;
  --bismark: #42687b;
  --border-color: #d9d9d9;
  --suva-gray: #888888;
  --see-icon: #e3efee;
  --light-gray-border: #e9e9e9;
  --dark-overlay: #202020;
  --gray-light-border: #d4d4d4;
  --theme-danger: #c83232;
  --light-bg: #fafafa;
  --light-theme-border: #f9eee7;
  --floating-champ: #a54c39;
  --excursions: #7382d0;
  --fountain-blue-light: #e6f0f3;
  --accordion-border-color: #b2cbd0;
  --private-barbeque: #e09562;
  --diving-water-sports: #00af87;
  --sports-recreation: #58b7cd;
  --floating-breakfast: #a84f3b;
  --ele-spa: #ed8a46;
  --kids-heaven: #a577d4;
}
@font-face {
  font-family: "Georgia-Regular";
  src: url("../fonts/georgia-webfont.woff2") format("woff2"),
    url("../fonts/georgia-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Georgia-Bold";
  src: url("../fonts/georgiab-webfont.woff2") format("woff2"),
    url("../fonts/georgiab-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
::selection {
  background-color: var(--theme-brown);
  color: var(--bs-white);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}
h1,
.h1 {
  font-size: 46px;
}
h2,
.h2 {
  font-size: 40px;
}
h3,
.h3 {
  font-size: 36px;
}
h4,
.h4 {
  font-size: 32px;
}
h6,
.h6 {
  font-size: 18px;
}
p {
  color: var(--fiord);
  line-height: 2.1;
}
img {
  max-width: 100%;
}
@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}
.smooth {
  @include transition(all 400ms ease-in-out 0s);
}
.georgia {
  font-family: "Georgia-Regular";
}
.georgia-bold {
  font-family: "Georgia-Bold";
}
form {
  .form-control {
    border-color: var(--border-color);
    border-radius: 4px;
  }
}
.btn {
  @extend .smooth;
  &.theme-btn {
    background-color: var(--theme-brown);
    padding: 13.5px 55px;
    text-align: center;
    font-size: 14px;
    &.border-theme {
      border-color: var(--theme-brown);
    }
    &:hover,
    &:focus {
      background-color: var(--gondola);
      border-color: var(--gondola);
      color: var(--bs-white) !important;
    }
  }
  &.theme-btn-outline {
    color: var(--theme-brown);
    border-color: var(--theme-brown);
    font-size: 20px;
    padding: 14px 27px;
    &:hover,
    &:focus {
      background-color: var(--theme-brown);
      border-color: var(--theme-brown);
      color: var(--bs-white) !important;
    }
  }
}
.section-padding {
  padding: 100px 0;
}
header {
  position: fixed;
  z-index: 999;
  @extend .smooth;
  padding: 22px 50px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 200px;
    background: rgb(51, 51, 51);
    background: linear-gradient(
      0deg,
      rgba(51, 51, 51, 0) 0%,
      rgb(51, 51, 51) 100%
    );
  }
  .header-wrap {
    z-index: 1;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 9px;
      width: calc(50% - 120px);
      height: 1px;
      background-color: rgba(255, 255, 255, 0.5);
      @extend .smooth;
    }
    &::after {
      left: initial;
      right: 0;
    }
  }
  .menu-btn {
    width: 30px;
    padding: 0;
    top: 25px;
    img {
      width: 30px;
    }
  }
  .atmosphere-living-nav {
    .navbar-nav {
      .nav-ul {
        width: calc(50% - 105px);
        &.end-nav-list {
          padding-left: 70px;
        }
        &.start-nav-list {
          justify-content: flex-end;
          padding-right: 70px;
        }
      }
      .nav-item {
        margin: 0 4px;
        .nav-link {
          padding: 18px 0;
          font-size: 14px;
          position: relative;
          @extend .smooth;
          &::before {
            content: "";
            height: 3px;
            width: 0;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 0;
            background-color: var(--bs-white);
            @extend .smooth;
          }
          &.active {
            &::before {
              width: 100%;
            }
          }
          &:hover {
            span {
              background-color: var(--bs-white);
              color: var(--theme-brown);
            }
          }
          span {
            padding: 10px 20px;
            display: flex;
            @extend .smooth;
            border-radius: 5px;
          }
        }
      }
      .booking-nav-item {
        .book-btn {
          border: 1px var(--theme-brown) solid;
          font-size: 14px;
          border-radius: 0.25rem;
          line-height: 1.7;
          background-color: var(--theme-brown);
          color: var(--bs-white);
          &:hover,
          &:focus {
            background-color: var(--gondola);
            border-color: var(--gondola);
          }
        }
      }
      .logo-item {
        width: 242px;
        position: relative;
        margin: 0 68.5px 0 210px !important;
        .nav-link {
          padding: 0 80px;
          &::before {
            content: none;
          }
        }
        .logo-fixed {
          display: none;
          top: 0;
        }
        .logo-sticky {
          top: -52px;
        }
      }
    }
  }
  .mobile-book-now {
    .book-btn {
      border: 1px solid var(--theme-brown);
      font-size: 14px;
      border-radius: 0.25rem;
      line-height: 1.7;
      background-color: var(--theme-brown);
      &:hover,
      &:focus {
        background-color: var(--gondola);
        border-color: var(--gondola);
        outline: 0;
        box-shadow: none;
      }
    }
  }
  .atmosphere-centered-logo {
    width: 208px;
    img {
      &.logo-fixed {
        display: none;
        top: -39px;
        max-width: 175px;
      }
      &.logo-sticky {
        top: -52px;
        max-width: 155px;
      }
    }
  }
  &.sticky {
    padding-top: 0;
    padding-bottom: 0;
    background-color: var(--theme-brown);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    &::after {
      content: none;
    }
    .navbar-nav {
      padding-bottom: 0;
    }
    .header-wrap {
      &::before,
      &::after {
        content: none;
      }
    }
    .atmosphere-living-nav {
      .navbar-nav {
        .logo-item {
          .logo-fixed {
            display: inline;
            top: -40px;
          }
          .logo-sticky {
            display: none;
          }
        }
        .booking-nav-item {
          .book-btn {
            background-color: var(--bs-white);
            color: var(--theme-brown);
            border-color: var(--bs-white);
            &:hover,
            &:focus {
              background-color: var(--dark-overlay);
              border-color: var(--dark-overlay);
              color: var(--bs-white);
            }
          }
        }
      }
    }
    .atmosphere-centered-logo {
      img {
        &.logo-fixed {
          display: block;
        }
        &.logo-sticky {
          display: none;
        }
      }
    }
  }
  navbar-toggler {
    @extend .smooth;
    &:focus {
      outline: 0;
      box-shadow: none;
      &:not(:focus-visible) {
        box-shadow: none;
      }
    }
  }
}
.atmosphere-hero-video {
  .scroll-down {
    bottom: 100px;
    span {
      display: block;
      line-height: 1;
      font-size: 18px;
      animation: arrow 2s infinite;
      -o-animation: arrow 2s infinite;
      -ms-animation: arrow 2s infinite;
      -moz-animation: arrow 2s infinite;
      -webkit-animation: arrow 2s infinite;
      &:nth-child(2) {
        font-size: 24px;
      }
      &:nth-child(3) {
        font-size: 29px;
      }
      &.arrow1 {
        animation-delay: -1s;
        -webkit-animation-delay: -1s;
      }
      &.arrow2 {
        animation-delay: -0.5s;
        -webkit-animation-delay: -0.5s;
      }
      &.arrow3 {
        animation-delay: 0s;
        -webkit-animation-delay: 0s;
      }
    }
  }
  @keyframes arrow {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
.atmosphere-book-now {
  padding: 24px 30px;
  max-width: 1630px;
  width: calc(100% - 100px);
  box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.2);
  backdrop-filter: blur(10px);
  margin-top: -80px;
  button {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 10px 6px;
    background-color: var(--bs-white);
    border: 1px solid var(--border-color);
    text-overflow: ellipsis;
    -webkit-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
    text-align: left;
    color: var(--gondola);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .guests-input {
    button {
      cursor: pointer;
      &::before,
      &::before {
        content: " ";
        width: 12px;
        height: 2px;
        border-radius: 2px;
        background-color: var(--gondola);
        position: absolute;
        top: 19px;
      }
      &::before {
        right: 15px;
        height: 7px;
        background-image: url(../images/thin-down-arrow.svg);
        background-color: transparent;
      }
      &::after {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        right: 21px;
      }
      &.open {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        &::after {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
  }
  .guests-input__options {
    position: absolute;
    width: 100%;
    background-color: var(--bs-white);
    -webkit-box-shadow: rgba(72, 72, 72, 0.2) 0 15px 20px;
    box-shadow: rgba(72, 72, 72, 0.2) 0 15px 20px;
    border-radius: 2px;
    overflow: hidden;
    height: 0;
    opacity: 0;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
    &.open {
      opacity: 1;
      height: 146px;
    }
    > div {
      padding: 10px 0;
      text-align: center;
      &:first-child {
        padding-top: 35px;
      }
      &:last-child {
        padding-bottom: 35px;
      }
    }
  }
  .guests-input__ctrl {
    display: inline-block;
    border: 1px solid var(--border-color);
    font-size: 20px;
    color: var(--gondola);
    padding: 3px 8px;
    line-height: 20px;
    border-radius: 2px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    &.disabled {
      color: var(--border-color);
      border-color: var(--border-color);
      cursor: default;
    }
  }
  .guests-input__value {
    display: inline-block;
    padding: 0 10px;
    width: 100px;
    cursor: default;
    span {
      display: inline-block;
      padding-right: 5px;
    }
  }
  .submit-form {
    margin-top: 30px;
  }
  .booking-form {
    .row {
      .col {
        &:last-child {
          max-width: 171px;
        }
      }
    }
  }
  .calendar-header {
    background-color: var(--theme-brown);
    color: var(--bs-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  #prevBtn,
  #nextBtn {
    background: none;
    border: none;
    color: var(--bs-white);
    cursor: pointer;
    font-size: 22px;
    width: auto;
  }
  #monthYear {
    font-size: 18px;
    font-weight: bold;
    color: var(--bs-white);
  }
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    padding: 10px;
    font-size: 14px;
  }
  .day {
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
  .day.current {
    background-color: var(--theme-brown);
    color: var(--bs-white);
  }
  .day.selected {
    background-color: var(--theme-brown);
    color: var(--bs-white);
  }
  #dateInput {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    outline: none;
    cursor: pointer;
  }
  .discount-wrap {
    .dropdown-menu {
      max-width: 376px;
      padding: 20px 28px;
      border-radius: 0;
      margin-top: 7px;
    }
  }
  form {
    label {
      font-size: 12px;
      color: var(--gondola);
      opacity: 1 !important;
    }
    .form-control {
      font-size: 14px;
      padding: 13.5px 20px;
      color: var(--gondola) !important;

      &:focus,
      &.show {
        border-color: var(--theme-brown);
        background-color: var(--bs-white);
        color: var(--bs-body-color);
      }
      &.downArrow {
        position: relative;
        &:before {
          position: absolute;
          right: 13px;
          top: calc(50% - 4px);
          content: "";
          background-image: url(../images/down-black-arrow.svg);
          background-repeat: no-repeat;
          background-size: 12px;
          width: 12px;
          height: 12px;
        }
      }
    }
    select.form-control,
    button.form-control,
    .checkin-out-date {
      background-image: url(../images/down-black-arrow.svg);
      background-repeat: no-repeat;
      background-size: 12px;
    }
    button.form-control {
      &:hover,
      &:focus,
      &:visited {
        background-color: transparent;
        color: inherit;
        border-color: var(--border-color);
      }
      &:focus,
      &:visited,
      &:active,
      &:focus-within {
        border-color: var(--theme-brown);
      }
      &::after {
        content: none;
      }
    }
    .dropdown {
      .guest-form-control {
        background-image: none;
        border-color: var(--border-color) !important;
      }
      &.show {
        .guest-form-control {
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }
  .submit-col {
    max-width: 185px;
  }
  .discount-dropdown {
    .form-control {
      padding-top: 8.5px;
      padding-bottom: 8.5px;
    }
  }
}
.small-heading {
  color: var(--theme-brown);
}
.heading-space {
  letter-spacing: 2.5px;
}
.our-sub-brands {
  padding-left: 37.5px;
  padding-right: 37.5px;
}
.cardOneBg {
  background: rgba(169, 122, 90, 0.8);
}
.cardTwoBg {
  background-color: rgba(157, 195, 204, 0.8);
}
.cardThreeBg {
  background-color: rgba(211, 168, 153, 0.8);
}
.cardFourBg {
  background-color: rgba(172, 159, 117, 0.8);
}
.cardFiveBg {
  background-color: rgba(170, 163, 175, 0.8);
}
.cardSixBg {
  background-color: rgba(176, 112, 96, 0.8);
}
.cardSevenBg {
  background-color: rgba(66, 104, 123, 0.8);
}
.sub-card-title {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  line-height: 1.3;
  height: 125px;
}
.sub-card {
  margin: 0 auto;
  box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.1);
  border-radius: 0 0 10px 10px;
  backdrop-filter: blur(5px);
  bottom: -235px;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  .cardOneBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--medium-wood);
      }
    }
  }
  .cardTwoBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--glacier);
      }
    }
  }
  .cardThreeBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--rose);
      }
    }
  }
  .cardFourBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--tallow);
      }
    }
  }
  .cardFiveBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--chatelle);
      }
    }
  }
  .cardSixBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--fire-brick);
      }
    }
  }
  .cardSevenBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--bismark);
      }
    }
  }
}
.sub-card-para {
  padding: 10px 35px 30px;
  p {
    line-height: 1.9;
    margin: 0;
    position: relative;
    color: var(--bs-white);
    min-height: 122px;
  }
  hr {
    border-width: 2px;
    opacity: 1;
    top: 0;
    width: 80px;
    margin: 35px auto 0;
    position: relative;
    @extend .smooth;
    &.cardBorderOneBg {
      border-color: var(--medium-wood);
    }
    &.cardBorderTwoBg {
      border-color: var(--glacier);
    }
    &.cardBorderThreeBg {
      border-color: var(--rose);
    }
    &.cardBorderFourBg {
      border-color: var(--tallow);
    }
    &.cardBorderFiveBg {
      border-color: var(--chatelle);
    }
    &.cardBorderSixBg {
      border-color: var(--fire-brick);
    }
    &.cardBorderSevenBg {
      border-color: var(--bismark);
    }
  }
  .card-link {
    top: 15px;
    a {
      color: var(--bs-white);
      text-transform: uppercase;
      text-decoration: none;
      @extend .smooth;
      display: flex;
      justify-content: center;
      &:hover,
      &:focus {
        transform: translateX(10px);
        opacity: 0.85;
        display: flex;
      }
      svg {
        margin-left: 20px;
      }
    }
  }
}
.sub-brand-card {
  background-color: var(--theme-brown);
  img {
    width: 100%;
    max-width: 280px;
  }
}
.feature-card {
  &:hover,
  &:focus {
    .sub-card {
      bottom: 0;
    }
    .sub-card-para {
      padding-top: 0;
    }
  }
}
.vertically-heading {
  z-index: 0;
  top: 580px;
  left: -40px;
  width: 0;
  h1 {
    transform: rotate(-90deg);
    letter-spacing: 15px;
  }
}
.view-all {
  font-size: 14px;
  color: var(--gondola);
  letter-spacing: 2px;
  align-items: center;
  @extend .smooth;
  svg {
    font-size: 14px;
    @extend .smooth;
  }
  &:focus,
  &:hover {
    color: var(--dark-brown) !important;
    transform: translate(10px);
    svg {
      color: var(--dark-brown) !important;
    }
  }
}
.hotels-resorts {
  padding-bottom: 50px;
  .slide-wrap {
    width: calc(100% - 800px);
  }
  .bottom-right-images {
    .thumb-wrap {
      padding: 35px 40px;
      max-width: 230px;
      width: 100%;
      height: auto;
      margin: 0 15px;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(10px);
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--border-color);
      box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.2);
      span {
        font-size: 14px;
      }
    }
    .thumb-img-box {
      height: 135px;
      margin: 30px auto 0;
      border-radius: 10px;
      width: 118px;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
  .left-control-btns {
    width: 140px;
  }
  .right-slide-progress-bar {
    width: calc(100% - 150px);
    padding: 0 42px 0 2px;
    margin: 0 auto;
    .linebar-slide {
      width: 100%;
      height: 1px;
      background-color: var(--theme-brown);
      display: flex;
    }
    .active-slide-bar {
      top: -1px;
      height: 3px;
      background-color: var(--theme-brown);
    }
    .line-slide-status {
      width: calc(100% - 60px);
      padding-right: 5px;
    }
    .slide-number {
      width: 60px;
      span {
        font-size: 32px;
        color: var(--theme-brown);
        letter-spacing: 3px;
      }
    }
  }
  .img-container-overlay {
    border-radius: 10px;
    height: 100%;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 70%;
      height: 100%;
      background: linear-gradient(
        109.13deg,
        rgba(9, 38, 48, 1) 0%,
        rgba(9, 38, 48, 0) 86.66679263114929%
      );
      border-radius: 10px;
    }
  }
  .slide-demo-box {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 70%;
      height: 100%;
      background: linear-gradient(
        109.13deg,
        rgba(9, 38, 48, 1) 0%,
        rgba(9, 38, 48, 0) 86.66679263114929%
      );
      border-radius: 10px;
    }
  }
  .hotel-resort-mobile-slide {
    border-radius: 10px;
    overflow: hidden;
    h1 {
      letter-spacing: 2px;
      margin-bottom: 20px;
      font-size: 45px;
    }
    span {
      font-size: 18px;
      letter-spacing: 3.5px;
    }
    small {
      font-size: 16px;
      letter-spacing: 3.5px;
    }
  }
  .mobile-slides-hotel-resort {
    .slick-slider {
      padding-bottom: 5px;
    }
    .slick-slide {
      padding: 0 15px;
    }
    .slick-prev,
    .slick-next {
      top: initial;
      bottom: 0;
      border: 1px var(--theme-brown) solid;
      background-color: transparent !important;
      width: 60px;
      height: 60px;
      background-size: 12px !important;
      transform: none;
      margin: 0 auto;
    }
    .slick-prev {
      background-image: url(../images/prev-arrow.svg) !important;
      right: 0;
      left: -150px;
    }
    .slick-next {
      background-image: url(../images/next-arrow.svg) !important;
      left: 0;
      right: -150px;
    }
    .slick-dots {
      bottom: 0;
      width: 50px;
      height: 50px;
      position: relative;
      left: 0;
      right: 0;
      margin: 30px auto 0;
      li {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        transition: all 900ms ease-in-out 0s;
        -o-transition: all 900ms ease-in-out 0s;
        -ms-transition: all 900ms ease-in-out 0s;
        -moz-transition: all 900ms ease-in-out 0s;
        -webkit-transition: all 900ms ease-in-out 0s;
        &.slick-active {
          opacity: 1;
          visibility: visible;
        }
      }
      button {
        background-color: transparent;
        font-size: 25px;
        color: var(--theme-brown);
        width: 100%;
        height: 100%;
        padding: 0;
        &::before {
          content: none;
        }
      }
    }
  }
}
#nextbutton,
#nextbuttondummy {
  background-color: transparent;
  &:focus {
    background-color: transparent;
  }
}
.hotels-resorts-slides {
  padding: 0 50px;
  overflow: hidden;
  .slick-item {
    img {
      width: 100%;
    }
    .img-container {
      border-radius: 10px;
      &::before {
        content: "";
        opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: var(--dark-theme);
      }
    }
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    margin-top: -105px;
    text-align: right;
    display: flex !important;
    max-width: 1040px;
    margin-left: auto;
    li {
      min-width: 230px;
      width: 100%;
      height: auto;
      margin: 0 15px;
      a {
        display: flex;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(10px);
        align-items: center;
        border-radius: 10px;
        border: 1px solid var(--border-color);
        box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.2);
        .thumb-wrap {
          padding: 35px 55px;
          span {
            font-size: 14px;
          }
        }
        .thumb-img-box {
          height: 135px;
          margin: 30px 0 0;
          border-radius: 10px;
          img {
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
  }
  .slide-caption {
    margin: 55px 0 0 130px;
    z-index: -1;
    h1 {
      letter-spacing: 10px;
      font-size: 56px;
    }
    span {
      font-size: 20px;
      letter-spacing: 7px;
    }
    .slide-small-text {
      background-image: url("../images/brush-stoke.png");
      background-repeat: no-repeat;
      background-position: center bottom;
    }
    small {
      font-size: 18px;
      letter-spacing: 4px;
    }
    a {
      font-size: 16px;
      svg {
        font-size: 18px;
      }
      &:hover,
      &:focus {
        color: var(--bs-white) !important;
        opacity: 0.85 !important;
        transform: translateX(10px);
        svg {
          transform: translateX(0);
          color: var(--bs-white) !important;
        }
      }
    }
  }
}
.gallery-slides {
  .slick-prev,
  .slick-next {
    opacity: 1;
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      display: flex;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .slick-prev {
    &::before {
      background-image: url("../images/prev.svg");
    }
  }
  .slick-next {
    &::before {
      background-image: url("../images/next.svg");
    }
  }
}
.gallery-card {
  padding: 0 12px 25px;
  .gallery-text-box {
    padding: 30px 15px 30px;
    @extend .smooth;
    width: calc(100% - 116px);
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 10px 10px 20px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(10px);
    bottom: -117px;
    overflow: hidden;
    p {
      font-size: 14px;
      line-height: 1.7;
      margin: 16px 0 0;
    }
  }
  .view-more-destination {
    @extend .smooth;
    transform: translateY(25px);
    overflow: hidden;
    margin-top: 35px;
    opacity: 0;
    height: 0;
    overflow: hidden;
    a {
      padding: 11.5px 34px;
    }
  }
  &:hover,
  &:focus {
    cursor: pointer;
    .view-more-destination {
      transform: translateY(0);
      opacity: 1;
      height: 46px;
    }
  }
}
.special-offers-slides {
  overflow: hidden;
  @extend .gallery-slides;
  .slick-list {
    margin-left: -12px;
    margin-right: -12px;
  }
  .slick-arrow {
    top: 30%;
    &.slick-prev {
      left: -75px;
    }
    &.slick-next {
      right: -75px;
    }
  }
  .gallery-text-box {
    width: calc(100% - 96px);
    margin-top: -115px;
    @extend .smooth;
    transform: scale(0);
    opacity: 0;
    padding-bottom: 30px !important;
    h4 {
      font-size: 40px;
    }
  }
  .slick-active {
    .gallery-text-box {
      transform: scale(1);
      opacity: 1;
    }
  }
  .card-image {
    img {
      height: 100%;
      object-fit: cover;
      aspect-ratio: 637/327;
    }
  }
  .slick-slide > div {
    text-align: center;
  }
  .gallery-card {
    max-width: 661px;
  }
}
.card-image {
  border-radius: 10px;
}
.special-offers {
  .gallery-text-box {
    small,
    span {
      font-size: 15px;
      color: var(--gondola);
    }
    h4 {
      color: var(--gondola);
    }
    p {
      a {
        color: var(--theme-brown);
      }
    }
  }
}
.sustainability {
  .sustainability-commitment {
    background-image: url("../images/sustainability.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 20px 265px;
    border-radius: 10px;
    span {
      letter-spacing: 3px;
    }
    p {
      max-width: 610px;
      font-size: 14px;
    }
  }
  .sustain-card {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid #e2e2e2;
    p {
      color: var(--fiord);
      font-size: 14px;
      line-height: 1.75;
    }
    .card-image {
      height: 70px;
      width: 70px;
      img {
        width: auto;
      }
    }
  }
  .commitment-feature {
    margin-top: -185px;
  }
}
.ask-form {
  h2 {
    color: var(--gondola);
  }
  p {
    color: var(--fiord);
    max-width: 880px;
  }
  .gdpr-compliance {
    p {
      max-width: 100%;
    }
  }
  form {
    .custom-label,
    .label-focused {
      color: var(--fiord);
      position: absolute;
      left: 20px;
      top: 0;
      padding: 0 10px;
      background-color: var(--bs-white);
      z-index: -1;
      @extend .smooth;
      transform: translateY(18.5px);
      -o-transform: translateY(18.5px);
      -ms-transform: translateY(18.5px);
      -moz-transform: translateY(18.5px);
      -webkit-transform: translateY(18.5px);
      pointer-events: none;
      &.label-focused {
        transform: translateY(-12px);
        -o-transform: translateY(-12px);
        -ms-transform: translateY(-12px);
        -moz-transform: translateY(-12px);
        -webkit-transform: translateY(-12px);
        color: var(--theme-brown);
        background-color: var(--bs-white);
        z-index: 1;
      }
    }
    .custom-label,
    .label-focused-email {
      color: var(--fiord);
      position: absolute;
      left: 20px;
      top: 0;
      padding: 0 10px;
      background-color: var(--bs-white);
      z-index: -1;
      @extend .smooth;
      transform: translateY(18.5px);
      -o-transform: translateY(18.5px);
      -ms-transform: translateY(18.5px);
      -moz-transform: translateY(18.5px);
      -webkit-transform: translateY(18.5px);
      pointer-events: none;
      &.label-focused-email {
        transform: translateY(-12px);
        -o-transform: translateY(-12px);
        -ms-transform: translateY(-12px);
        -moz-transform: translateY(-12px);
        -webkit-transform: translateY(-12px);
        color: var(--theme-green);
        background-color: var(--bs-white);
        z-index: 1;
      }
    }
    .label-focused-email {
      color: var(--theme-brown) !important;
    }
    .form-control {
      border-color: var(--green-border);
      padding: 17px 30px;
      box-shadow: none;
      border-radius: 6px;
      background-color: transparent;
      @extend .smooth;
      color: var(--fiord);
      &:focus,
      &.PhoneInput--focus {
        border-color: var(--theme-brown);
      }
    }
    .PhoneInputInput {
      padding: 0;
      border: 0 !important;
      outline: 0 !important;
    }
    .PhoneInputCountry {
      margin-right: 20px;
      border-right: 1px var(--green-border) solid;
      padding-right: 20px;
    }
    .PhoneInputCountrySelectArrow {
      border: 0;
      height: 10px;
      width: 10px;
      background-image: url("../images/down-arrow.svg");
      background-repeat: no-repeat;
      transform: none;
      opacity: 1;
      margin-left: 17px;
    }
    .PhoneInputCountryIcon--border {
      border: 0;
      box-shadow: none;
    }
    .theme-btn {
      padding: 13.5px 44px;
      color: var(--theme-brown) !important;
    }
    .check-terms {
      width: calc(100% - 417px);
      span {
        color: var(--fiord);
        font-size: 14px;
        width: calc(100% - 22px);
        padding-left: 15px;
      }
      input[type="checkbox"] {
        position: absolute;
        left: -99999px;
        top: 0;
      }
      .custom-check {
        width: 22px;
        height: 22px;
        border: 1px solid var(--fiord);
        border-radius: 4px;
        position: relative;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          left: 4px;
          top: 4px;
          position: absolute;
        }
      }
      input:checked + .custom-check {
        &::before {
          background-color: var(--fiord);
        }
      }
    }
  }
}
footer {
  .footer-newsletter {
    background-color: var(--theme-brown);
    h6 {
      font-size: 18px;
      letter-spacing: 4px;
    }
    form {
      max-width: 480px;
      width: 100%;
      margin-left: 75px;
      .form-control {
        border-radius: 6px 0 0 6px;
        font-size: 14px;
        color: var(--bs-white);
        height: 50px;
        &:focus {
          outline: 0;
          border-color: var(--border-color);
        }
      }
      button {
        border-radius: 0 6px 6px 0;
        border-color: var(--border-color);
        border-left: 0;
        padding: 0 27px;
        background-color: transparent;
        color: var(--bs-white);
        @extend .smooth;
        svg {
          margin: 0 15px 0 0;
        }
        &:hover,
        &:focus,
        &:active,
        &:visited,
        &:active,
        &:focus-visible {
          background-color: var(--bs-white) !important;
          border-color: var(--bs-white) !important;
          color: var(--theme-brown) !important;
          box-shadow: none;
        }
        &:not(.btn-check) + .btn:active {
          background-color: var(--bs-white);
          border-color: var(--bs-white);
          color: var(--theme-brown);
        }
      }
      ::-webkit-input-placeholder {
        color: var(--bs-white);
      }
      ::-moz-placeholder {
        color: var(--bs-white);
      }
      :-ms-input-placeholder {
        color: var(--bs-white);
      }
      :-moz-placeholder {
        color: var(--bs-white);
      }
    }
  }
  .mein-footer {
    background-color: var(--gondola);
    .footer-wrap {
      padding: 30px 55px 0;
    }
    .footer-logo {
      img {
        aspect-ratio: 107/51;
        max-width: 155px;
        width: 100%;
      }
    }
    .footer-col {
      span {
        font-size: 14px;
        letter-spacing: 1.75px;
      }
      address {
        font-size: 14px;
        color: var(--bs-white);
        max-width: 198px;
        opacity: 0.8;
      }
      .phone-footer {
        a {
          text-decoration: none;
          color: var(--sinbad);
          font-size: 20px;
          letter-spacing: 2px;
        }
      }
      .email-footer {
        a {
          letter-spacing: 0;
          color: var(--bs-white);
          font-size: 14px !important;
          opacity: 0.8;
        }
      }
      .icon-phone {
        opacity: 0.8;
        margin-right: 10px;
        color: var(--theme-brown);
      }
    }
    .useful-links-col {
      padding-left: 100px;
    }
    .footer-links {
      a {
        text-decoration: none;
        color: var(--bs-white);
        font-size: 14px;
        opacity: 0.8;
        display: inline-block;
        &:not(:last-child) {
          span {
            padding-right: 32px;
          }
        }
        span {
          letter-spacing: 0;
        }
      }
    }
  }
  .bottom-footer {
    padding: 20px 55px;
    border-top: 1px rgba(255, 255, 255, 0.2) solid;
    margin: 30px 0 0;
    font-size: 14px;
    color: var(--suva-gray);
    .footer-social {
      a {
        color: var(--bs-white);
        font-size: 17px;
        padding: 0 25px;
        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }
  .copyright,
  .atmosphere-core-logo {
    max-width: 375px;
    width: 100%;
    a {
      color: var(--suva-gray);
    }
  }
  .atmosphere-core-logo {
    justify-content: flex-end;
    img {
      max-width: 190px;
      aspect-ratio: 38/5;
    }
  }
  .scroll-to-top {
    background-color: var(--theme-brown);
    right: 10px;
    bottom: 90px;
    background-image: url("../images/scrolltop.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    svg {
      display: none;
    }
  }
}
.header-space {
  padding-top: 135px;
}
.inner-cover {
  .our-story {
    width: 100%;
  }
  img {
    object-fit: cover;
    aspect-ratio: 2121/537;
  }
}
.page-inner {
  padding: 100px 0 60px 0;
  z-index: 1;
  .breadcrumb {
    display: flex;
    align-items: center;
    a {
      color: var(--bs-white);
      text-decoration: none;
      font-size: 14px;
    }
    .breadcrumb-item + .breadcrumb-item {
      color: var(--bs-white);
      text-transform: capitalize;
      font-size: 14px;
      &::before {
        content: ">";
        color: var(--bs-white);
        padding: 0 15px;
      }
    }
  }
}
.page-title {
  h2 {
    letter-spacing: 22.5px;
    font-family: "Georgia-Bold";
    border-top: 1px rgba(255, 255, 255, 0.5) solid;
    border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
    line-height: 1.6;
  }
}
.dashed-heading {
  h2,
  h3,
  h4,
  h5 {
    letter-spacing: 9px;
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      bottom: 24px;
      width: 100%;
      content: "";
      border-top: 1px dashed var(--theme-brown);
    }
    span {
      background-color: var(--bs-white);
      position: relative;
      padding-right: 22px;
    }
  }
  h4 {
    letter-spacing: 4px;
    &::before {
      bottom: 13px;
    }
  }
  h5 {
    &::before {
      bottom: 11px;
    }
    span {
      padding-right: 8px;
    }
  }
}
.dashed-heading {
  h3 {
    letter-spacing: 6px;
    &::before {
      bottom: 20px;
      border-top: 1px dashed var(--theme-brown);
    }
    span {
      padding-right: 10px;
    }
  }
}
.value-card-img,
.feature-left-image {
  img {
    border-radius: 10px;
  }
}
.feature-left-image {
  aspect-ratio: 106/69;
  width: 100%;
}
.value-card-text {
  .value-card-title {
    padding: 0 45px;
    margin: 105px 0 185px;
  }
  padding: 0 35px;
  span {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    color: var(--gondola);
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 10px 10px 20px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(10px);
    padding: 25px 20px;
    margin: 0;
    height: 252px;
    line-height: 1.8;
  }
}
.right-feature-text-col {
  max-width: 805px;
  .right-feature-wrap {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    padding: 103px 65px 55px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 6px 6px 20px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(15px);
  }
  .top-head-text {
    h3 {
      color: var(--theme-brown);
    }
    span {
      @extend h3;
      font-size: 20px;
    }
  }
  .feature-text-para {
    margin: 60px 0 0;
    &::after {
      content: "";
      width: 145px;
      height: 3px;
      background-color: var(--theme-brown);
      display: flex;
      margin: 60px auto 0;
    }
    p {
      font-size: 20px;
      font-family: "Georgia-Regular";
      color: var(--gondola);
      line-height: 1.9;
    }
  }
}
.book-now-modal {
  .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }
  .modal-content {
    background-color: rgba(0, 0, 0, 0.2);
    border: 0;
    padding: 25px 45px 35px;
  }
  .modal-dialog {
    max-width: 855px;
  }
  .btn-close {
    right: 10px;
    top: 10px;
    position: absolute;
  }
  form {
    label {
      font-size: 14px;
    }
    .form-control {
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(5px);
      padding: 17px 20px;
      border-radius: 8px;
    }
  }
  .form-submit {
    .theme-btn {
      background-color: var(--dark-brown);
      border-color: var(--dark-brown);
      padding: 18.5px 47px;
      &:hover,
      &:focus {
        background-color: var(--fiord);
        border-color: var(--fiord);
      }
    }
  }
  .PhoneInputInput {
    background-color: transparent;
    border: 0;
    padding: 17px 15px 17px;
    &:focus {
      border-color: transparent;
      outline: 0;
      box-shadow: none;
    }
  }
  .PhoneInputCountry {
    margin-right: 0;
    padding-left: 20px;
  }
  .PhoneInputCountryIcon--border {
    border: 0;
    box-shadow: none;
    border-radius: 2px;
    overflow: hidden;
    margin-right: 8px;
  }
  .PhoneInputCountrySelectArrow {
    opacity: 1;
  }
  .form-error {
    line-height: 1.7;
  }
  .PhoneInputCountrySelect {
    &:focus {
      + .PhoneInputCountryIcon--border {
        box-shadow: none;
      }
      + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
        color: initial;
      }
    }
  }
}
.modal-title {
  letter-spacing: 5px;
  word-break: break-all;
}
.btn-close {
  color: var(--bs-white);
  background-image: url("../images/close.svg");
  background-repeat: no-repeat;
  opacity: 1;
}
.modal-open {
  header,
  footer,
  section {
    filter: blur(10px);
  }
}
.input-number {
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}
.input-number-increment,
.input-number-decrement {
  background-color: transparent;
  border: 0;
  width: 35px;
  color: var(--gondola);
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 1;
}
.input-number-increment {
  left: 0;
  text-align: right;
}
.input-number-decrement {
  right: 0;
  text-align: left;
}
.sub-brands-tabs {
  padding-left: 50px;
  padding-right: 50px;
  overflow: hidden;
  .rts___tab {
    border: 1px var(--border-color) solid;
    padding: 7px;
    position: relative;
    @extend .smooth;
    &:hover,
    &:focus {
      border-color: var(--theme-brown);
      color: var(--theme-brown);
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      bottom: -10px;
    }
    &.rts___tab___selected {
      background-color: var(--theme-brown);
      border-color: var(--theme-brown);
      color: var(--bs-white);
      &::before {
        border-top-color: var(--theme-brown);
      }
    }
    &:first-child {
      &.rts___tab___selected {
        background-color: var(--medium-wood);
        border-color: var(--medium-wood);
        &::before {
          border-top-color: var(--medium-wood);
        }
      }
    }
    &:nth-child(2) {
      &.rts___tab___selected {
        background-color: var(--glacier);
        border-color: var(--glacier);
        &::before {
          border-top-color: var(--glacier);
        }
      }
    }
    &:nth-child(3) {
      &.rts___tab___selected {
        background-color: var(--rose);
        border-color: var(--rose);
        &::before {
          border-top-color: var(--rose);
        }
      }
    }
    &:nth-child(4) {
      &.rts___tab___selected {
        background-color: var(--tallow);
        border-color: var(--tallow);
        &::before {
          border-top-color: var(--tallow);
        }
      }
    }
    &:nth-child(5) {
      &.rts___tab___selected {
        background-color: var(--chatelle);
        border-color: var(--chatelle);
        &::before {
          border-top-color: var(--chatelle);
        }
      }
    }
    &:nth-child(6) {
      &.rts___tab___selected {
        background-color: var(--fire-brick);
        border-color: var(--fire-brick);
        &::before {
          border-top-color: var(--fire-brick);
        }
      }
    }
    &:nth-child(7) {
      &.rts___tab___selected {
        background-color: var(--bismark);
        border-color: var(--bismark);
        &::before {
          border-top-color: var(--bismark);
        }
      }
    }
  }
  .tab-feature-img {
    width: 45px;
    height: 45px;
  }
  .tab-name {
    padding: 0 20px;
    width: calc(100% - 45px);
    font-size: 14px;
  }
  .rts___nav___btn {
    border: 0;
    background-color: transparent;
    &:hover,
    &:focus {
      background-color: transparent;
      svg {
        stroke: var(--bs-black);
      }
    }
    &:disabled {
      opacity: 0.75;
    }
  }
}
.tab-text-view {
  padding: 35px 70px 80px;
  margin-top: -100px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: auto;
  border: 1px solid;
  .top-logo {
    &::before {
      content: "";
      left: 0;
      right: 0;
      top: calc(50% + 10px);
      position: absolute;
      height: 1px;
      border-top: 1px var(--theme-brown) dashed;
    }
  }
  .tabpara {
    max-width: 825px;
  }
  .hotel-view-tab {
    max-width: 865px;
    border-radius: 30px;
    padding: 23px;
    border: 1px var(--border-color) solid;
    @extend .smooth;
    position: relative;
    &:hover {
      box-shadow: 0 6px 15px 1px rgba(0, 0, 0, 0.15);
    }
  }
  .left-thumb-img {
    border-radius: 20px;
    overflow: hidden;
  }
  .right-hotel-text {
    width: calc(100% - 387px);
    padding-left: 60px;
    h4 {
      letter-spacing: 5px;
      color: var(--gondola);
      text-transform: uppercase;
    }
    p {
      letter-spacing: 3px;
      @extend h4;
      margin: 30px 0 0;
    }
    .visit-now {
      margin: 60px 0 0;
      a {
        text-decoration: none;
        font-size: 14px;
        color: var(--gondola);
        letter-spacing: 2px;
        font-weight: normal;
        display: flex;
        align-items: center;
        @extend .smooth;
        &:hover,
        &:focus {
          color: var(--dark-brown);
          transform: translateX(10px);
        }
      }
    }
  }
}
.tab-top-header {
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
}
.brandone {
  .tab-text-view {
    background-image: url(../images/kanifushi-leaveone.svg);
    background-position: 150px 68%, 0 0;
    border: 1px rgba(110, 154, 149, 0.6) solid;
    &::after,
    &::before {
      content: "";
      width: 180px;
      height: 70%;
      position: absolute;
      left: calc(100% - 16px);
      top: 30%;
      background-image: url(../images/tab-one-leaves.svg),
        url(../images/tab-one-sun.svg);
      background-position: left calc(100% - 40px), right 0;
      background-repeat: no-repeat;
      z-index: -1;
    }
    &::before {
      background-image: url(../images/tab-one-glass.svg);
      background-position: center;
      width: 54px;
      height: 110px;
      left: -110px;
      top: 60%;
    }
  }
  .atmosphere-discover {
    &::after,
    &::before {
      content: "";
      background-image: url("../images/spex.svg"), url("../images/chappel.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 50px) calc(50% - -10px), 0 bottom;
      width: 250px;
      height: 100%;
      position: absolute;
      left: -250px;
      top: 0;
      z-index: -1;
    }
    &::before {
      background-image: url("../images/ship.svg");
      background-position: center;
      left: initial;
      right: -120px;
      width: 59px;
      height: 40px;
      top: initial;
      bottom: 7px;
    }
  }
}
.brandtwo {
  .tab-text-view {
    border: 1px rgba(133, 177, 187, 0.6) solid;
    &::after,
    &::before {
      content: "";
      background-image: url(../images/tabtwo-boat.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: calc(50% - 30px);
      height: 80px;
      width: 68px;
      left: -130px;
    }
    &::before {
      background-image: url(../images/tabtwo-cart.svg);
      left: inherit;
      right: -90px;
      top: calc(50% - 70px);
    }
    .hotel-view-tab {
      &::before {
        content: "";
        position: absolute;
        left: -80px;
        top: 60px;
        width: 53px;
        height: 53px;
        background-image: url(../images/tabtwo-ball.svg);
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--glacier);
    }
  }
  .download-brochure {
    background-color: var(--glacier);
  }
  .atmosphere-discover {
    &::after,
    &::before {
      content: "";
      background-image: url("../images/tabtwo-ball.svg"),
        url("../images/tabtwo-cart.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 90px) calc(50% - 50px),
        calc(100% - 40px) calc(100% - 60px);
      width: 250px;
      height: 100%;
      position: absolute;
      left: -250px;
      top: 0;
      z-index: -1;
    }
    &::before {
      background-image: url("../images/tabtwo-ball.svg"),
        url("../images/tabtwo-boat.svg");
      background-position: 20px 40%, right calc(100% - 120px);
      left: initial;
      right: -150px;
      width: 150px;
      height: 100%;
      top: initial;
      bottom: 7px;
    }
  }
}
.brandthree {
  .tab-text-view {
    border: 1px rgba(211, 168, 153, 0.6) solid;
    &::after,
    &::before {
      content: "";
      background-image: url(../images/shipwater-experience.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: calc(50% + 20px);
      height: 74px;
      width: 162px;
      left: -220px;
    }
    &::before {
      background-image: url(../images/tree-experience.svg);
      left: inherit;
      right: 0;
      bottom: 0;
      width: 59px;
      height: 89px;
      top: initial;
    }
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--rose);
    }
  }
  .download-brochure {
    background-color: var(--rose);
  }
  .atmosphere-discover {
    &::after,
    &::before {
      content: "";
      background-image: url("../images/ship-experience.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 162px;
      height: 42px;
      position: absolute;
      left: -212px;
      top: 64%;
      z-index: -1;
    }
    &::before {
      background-image: url("../images/shipwater-experience.svg");
      background-position: center;
      left: initial;
      right: -220px;
      width: 162px;
      height: 74px;
      top: 45%;
    }
  }
}
.brandfour {
  .tab-text-view {
    border: 1px rgba(172, 159, 117, 0.6) solid;
    &::after,
    &::before {
      content: "";
      background-image: url(../images/tabtfour-birds.svg);
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 40%;
      height: 74px;
      width: 162px;
      left: -250px;
    }
    &::before {
      background-image: url(../images/tabtfour-mandala.svg);
      left: inherit;
      right: -40px;
      width: 59px;
      height: 89px;
      top: 35%;
      z-index: -1;
    }
  }
  .resorts-view-all {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 94px;
      top: 38%;
      width: 58px;
      height: 58px;
      background-image: url("../images/tabtfour-mandala.svg");
      background-repeat: no-repeat;
    }
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--tallow);
    }
  }
  .download-brochure {
    background-color: var(--tallow);
  }
  .atmosphere-discover {
    &::after,
    &::before {
      content: "";
      background-image: url(../images/tabtfour-mandala.svg),
        url(../images/tabtfour-birds-reverse.svg);
      background-repeat: no-repeat;
      background-position: right 0, center bottom;
      width: 157px;
      height: 487px;
      position: absolute;
      left: -212px;
      bottom: 70px;
      z-index: -1;
    }
    &::before {
      left: initial;
      right: -170px;
      background-position: left bottom, calc(100% - 20px) 0;
      bottom: initial;
      top: 27%;
    }
  }
}
.brandfive {
  .tab-text-view {
    border-color: rgba(170, 163, 175, 0.6);
    &::after,
    &::before {
      content: "";
      background-image: url(../images/bubbles.svg),
        url(../images/large-star.svg);
      background-repeat: no-repeat;
      background-position: center 0, center bottom;
      position: absolute;
      top: 50%;
      height: 281px;
      width: 108px;
      left: -130px;
    }
    &::before {
      background-image: url(../images/bubbles.svg);
      width: 43px;
      height: 69px;
      left: initial;
      right: -90px;
      top: 75%;
    }
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--chatelle);
    }
  }
  .hotel-view-tab {
    &::before {
      content: "";
      position: absolute;
      left: -80px;
      bottom: 60px;
      width: 76px;
      height: 85px;
      background-image: url(../images/leaf-chubby.svg);
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .download-brochure {
    background-color: var(--chatelle);
  }
  .atmosphere-discover {
    &::after,
    &::before {
      content: "";
      background-image: url("../images/palm-leaf.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 92px;
      height: 85px;
      position: absolute;
      left: -80px;
      top: 50%;
      z-index: -1;
    }
    &::before {
      background-image: url("../images/leaf-chubby.svg");
      background-position: center;
      left: initial;
      right: -51px;
      width: 92px;
      height: 69px;
      top: 60%;
      transform: rotate(90deg);
    }
  }
}
.brandsix {
  .tab-text-view {
    border-color: rgba(160, 54, 29, 0.26);
    background-color: var(--bs-white);
  }
  .tab-text-view {
    &::before,
    &::after {
      content: "";
      background-image: url("../images/fire-brick-camel.svg");
      background-position: center;
      position: absolute;
      left: -90px;
      width: 78px;
      height: 84px;
      bottom: 0;
      transform: rotateY(180deg);
    }
    &::after {
      background-image: url("../images/fire-brick-birds.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 91px;
      height: 59px;
      left: initial;
      right: -160px;
      bottom: 25%;
    }
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--fire-brick);
    }
  }
  .download-brochure {
    background-color: var(--fire-brick);
  }
  .atmosphere-discover {
    &::after,
    &::before {
      content: "";
      background-image: url("../images/fire-brick-birds.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 91px;
      height: 59px;
      position: absolute;
      left: -180px;
      bottom: 18%;
      z-index: -1;
    }
    &::before {
      background-image: url("../images/fire-brick-camel.svg");
      background-position: center;
      left: initial;
      right: -80px;
      width: 78px;
      height: 84px;
      top: 60%;
    }
  }
}
.brandseven {
  .tab-text-view {
    border-color: rgba(60, 104, 123, 0.3);
    &::before {
      content: "";
      background-image: url("../images/buldings-lines.svg");
      background-position: center;
      position: absolute;
      right: 40px;
      width: 106px;
      height: 188px;
      bottom: 0;
      transform: rotateY(180deg);
    }
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--bismark);
    }
  }
  .download-brochure {
    background-color: var(--bismark);
  }
}
.resorts-view-all {
  gap: 28px;
}
.resort-item {
  border-radius: 30px;
  border: 1px var(--border-color) solid;
  padding: 22px 22px 35px;
  box-shadow: 0 6px 10px 1px rgba(0, 0, 0, 0.05);
  background-color: var(--bs-white);
  position: relative;
}
.resort-pic {
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.resort-name-location {
  text-align: center;
  margin: 35px 0 0;
  h6 {
    font-size: 20px;
    letter-spacing: 2px;
  }
  span {
    font-size: 14px;
    letter-spacing: 3px;
  }
}
.rts___tabs___container {
  max-width: 1320px;
  margin: auto;
}
.atmosphere-discover {
  background-position: 12.2% 43%, 7% calc(100% - 110px), 89% calc(100% - 110px);
  position: relative;
}
.discover-left,
.discover-right {
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  .discover-card {
    display: flex;
    &:nth-child(even) {
      flex-direction: row-reverse;
      .discover-card-body {
        &::before {
          left: initial;
          right: -13px;
          transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }
  }
  .discover-card-image,
  .discover-card-body {
    width: 50%;
  }
  .discover-card-body {
    padding: 25px 80px;
    box-shadow: inset 0 0 0 1px var(--border-color);
    min-height: 292.583px;
    background-color: var(--bs-white);
    &::before {
      content: "";
      position: absolute;
      left: -13px;
      top: calc(50% - 17px);
      width: 52px;
      height: 34px;
      background-image: url("../images/discover-card-triangle.svg");
      background-repeat: no-repeat;
      z-index: 1;
    }
  }
  .discover-feature-icon {
    width: 88px;
    height: 88px;
    background-color: var(--medium-wood);
  }
  .discover-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--gondola);
  }
  .discover-text {
    p {
      font-size: 14px;
      font-weight: normal;
    }
  }
}
.discover-right {
  border-radius: 0 10px 10px 0;
  .discover-card {
    flex-wrap: wrap;
  }
  .discover-card-image,
  .discover-card-body {
    width: 100%;
  }
  .discover-card-body {
    &::before {
      right: 0;
      left: 0;
      margin: 0 auto;
      top: -6px;
      transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
  }
}
.download-brochure {
  height: 292.583px;
  background-color: var(--medium-wood);
  font-size: 20px;
  letter-spacing: 6px;
}
.contact-card {
  border-radius: 20px;
  border: 1px solid var(--border-color);
  box-shadow: 0px -3px 0 var(--theme-brown) inset;
  padding: 27px 30px;
  h4,
  h5 {
    color: var(--theme-brown);
  }
  h5 {
    font-size: 24px;
  }
  p {
    color: var(--gondola);
    letter-spacing: 4.5px;
    margin: 0;
  }
  .content-text {
    min-height: 70px;
    a {
      color: var(--gondola);
      text-decoration: none;
      letter-spacing: 4.5px;
    }
  }
}
.contact-via-social {
  gap: 24px;
  a {
    width: 88px;
    height: 88px;
    border-radius: 20px;
    border: 1px var(--theme-brown) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--theme-brown);
    font-size: 25px;
    @extend .smooth;
    img {
      filter: invert(58%) sepia(11%) saturate(827%) hue-rotate(124deg)
        brightness(97%) contrast(88%);
      @extend .smooth;
      width: 23px;
    }
    &:hover,
    &:focus {
      background-color: var(--theme-brown);
      color: var(--bs-white);
      img {
        filter: none;
      }
    }
  }
}
.map-location {
  iframe {
    border-radius: 20px;
    overflow: hidden;
  }
}
.site-visit-links {
  svg {
    transform: rotate(45deg);
  }
}
.sustainability-cards {
  overflow: hidden;
  .right-feature-text-col {
    max-width: 611px;
    .right-feature-wrap {
      padding: 60px 50px;
      margin-top: 70px;
    }
    .top-head-text {
      h4 {
        color: var(--gondola);
        letter-spacing: 3px;
        font-size: 28px;
      }
    }
    .feature-text-para {
      margin: 30px 0 0;
      p {
        font-family: arial;
        font-size: 16px;
        opacity: 0.8;
      }
      &::after {
        content: none;
      }
    }
  }
}
.learnmore {
  a {
    font-size: 14px;
    color: var(--theme-brown);
    @extend .smooth;
    display: inline-block;
    &:hover,
    &:focus {
      color: var(--dark-theme);
      transform: translateX(10px);
    }
  }
}
.slide-arrow-btns {
  width: 60px;
  height: 60px;
  border: 1px var(--theme-brown) solid;
  cursor: pointer;
}
.slider-bottom-actions {
  margin-top: -90px;
  padding-bottom: 50px;
}
.brands-slide {
  overflow: hidden;
}
.environmental-tourism {
  background-image: url("../images//environmental-tourism.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  .environmental-tourism-card {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.86);
    box-shadow: 6px 6px 20px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(15px);
    width: 100%;
    max-width: 688px;
    padding: 65px;
  }
}
.offers-tabs {
  .rts___tab {
    min-width: 251px;
  }
  .rts___tabs___container {
    justify-content: center;
  }
}
.offers-tabs {
  .offer-info-card {
    max-width: 665px;
    .right-feature-wrap {
      padding: 30px 35px;
      width: 100%;
    }
    .hotel-heading-wrap {
      span {
        font-size: 15px;
        color: var(--gondola);
      }
      h2 {
        letter-spacing: 2px;
        font-weight: 700;
        margin: 10px 0 5px;
      }
      p {
        font-size: 14px;
        margin: 18px 0 25px;
        word-break: break-word;
        a {
          letter-spacing: 0;
          color: var(--theme-brown);
        }
      }
    }
  }
  .offer-img {
    img {
      border-radius: 10px;
    }
  }
  &.sub-brands-tabs {
    .rts___tab {
      &.rts___tab___selected {
        background-color: var(--theme-brown);
        border-color: var(--theme-brown);
        &::before {
          border-top-color: var(--theme-brown);
        }
      }
    }
  }
}
.all-offers-list {
  .tab-top-header {
    &:nth-child(odd) {
      .offer-info-card {
        right: 0;
      }
      padding: 90px 0;
    }
    &:nth-child(even) {
      .col-md-9 {
        margin-left: 25%;
      }
    }
    &:first-child {
      padding-top: 0 !important;
    }
  }
  .offer-img {
    aspect-ratio: 483/248;
    width: 100%;
  }
}
.offer-details {
  .dashed-heading {
    h2 {
      letter-spacing: 4.5px;
    }
  }
}
.large-img-offer {
  aspect-ratio: 1272/653;
  img {
    border-radius: 10px;
  }
}
.offer-points {
  ul {
    padding-left: 15px;
  }
  li {
    color: var(--theme-brown);
    padding: 5px 0;
    &::marker {
      font-size: 20px;
    }
    span {
      color: var(--dim-gray);
      padding-left: 4px;
    }
  }
}
.media-center-tabs {
  .rts___tabs___container {
    justify-content: center;
    margin: 0 auto 60px;
    width: 100%;
  }
  .rts___tab {
    padding: 9px;
    min-width: 310px;
    &.rts___tab___selected {
      background-color: var(--theme-brown) !important;
      border-color: var(--theme-brown) !important;
      &::before {
        border-top-color: var(--theme-brown) !important;
      }
      .tab-feature-img {
        background-color: var(--bs-white);
        img {
          filter: invert(59%) sepia(63%) saturate(299%) hue-rotate(144deg)
            brightness(98%) contrast(89%);
        }
      }
    }
  }
  .tab-name {
    font-size: 16px;
  }
  .tab-feature-img {
    background-color: var(--theme-brown);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 28px;
    }
  }
  .tab-name {
    width: calc(100% - 60px);
  }
}
.media-para {
  max-width: 765px;
}
.press-releases-cards {
  .row {
    .col-lg-6 {
      &:nth-child(even) {
        .card-body {
          margin-left: auto;
        }
      }
    }
  }
  .card-image {
    img {
      border-radius: 8px;
      width: 100%;
    }
  }
  .card-body {
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 7px 7px 14px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(7px);
    border-radius: 8px;
    width: calc(100% - 114px);
    margin: -115px 26px 0;
    padding: 40px 35px 35px;
  }
  h4 {
    line-height: 1.5;
    color: var(--gondola);
  }
  .read-more {
    a {
      color: var(--theme-brown);
      font-size: 14px;
      @extend .smooth;
      display: inline-block;
      &:hover,
      &:focus {
        color: var(--dark-theme);
        transform: translateX(10px);
      }
    }
  }
}
.show-more {
  margin: 60px 0 0;
}
.btn {
  &.theme-outline-btn {
    background-color: transparent;
    color: var(--theme-brown);
    padding-left: 38px;
    padding-right: 38px;
  }
}
.press-details {
  .press-img {
    margin: 0 0 40px;
    aspect-ratio: 214/129;
  }
  .press-title {
    width: calc(100% - 200px);
    h3 {
      color: var(--gondola);
    }
  }
  .press-date {
    span {
      font-size: 14px;
    }
  }
  .press-full-article {
    margin: 50px 0 0;
  }
  .press-article-pagination {
    margin: 40px 0 0;
    display: none;
  }
}
.more-articles-sidebar {
  padding-left: 125px;
  .dashed-heading {
    h5 {
      letter-spacing: 0;
    }
  }
  .thumb-card-title {
    h6 {
      line-height: 1.5;
    }
  }
  .thumb-card-img {
    span {
      background: rgba(255, 255, 255, 0.85);
      box-shadow: 7px 7px 14px 0 rgba(115, 157, 152, 0.2);
      backdrop-filter: blur(7px);
      font-size: 13px;
      margin: 8px;
    }
  }
}
.thumb-articles {
  .thumb-press-card {
    margin-bottom: 55px;
    .thumb-card-img {
      aspect-ratio: 291/172;
    }
  }
}
.sub-brand-subtitle {
  h4 {
    letter-spacing: 2px;
  }
}
.media-center-tabs {
  form {
    .select-form {
      max-width: 360px;
      margin: 0 12px;
    }
    .form-control {
      font-size: 14px;
      padding: 13.5px 20px;
      color: var(--fiord);
    }
  }
}
.award-card {
  border-color: var(--green-border) !important;
  padding: 45px 60px;
  .award-by {
    span {
      min-height: 78px;
      font-size: 14px;
      max-width: 260px;
      margin-top: 15px !important;
    }
  }
}
.collateral-card {
  border-color: var(--green-border) !important;
  border-radius: 20px;
  padding: 30px;
  .collateral-text {
    padding: 0 15px;
    width: calc(100% - 167px);
    h5 {
      font-size: 22px;
      color: var(--gondola);
    }
    p {
      color: var(--fiord);
      margin: 0;
      line-height: 1.5;
    }
  }
  .collateral-docs-type {
    background-color: var(--faded-brown);
    border-radius: 20px;
    width: 112px;
    height: 112px;
  }
  .download-docs {
    a {
      width: 55px;
      height: 55px;
      border-radius: 15px;
      background-color: var(--theme-brown);
    }
  }
}
.under-construction-img {
  img {
    max-width: 500px;
  }
}
.entrance-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.entrance-animation-entered {
  opacity: 1;
  transform: translateY(0);
}
.green-globe-icon {
  aspect-ratio: 1/1;
  top: -50px;
  right: -1px;
}
.img-container {
  padding-left: 50px;
  padding-right: 50px;
  height: 450px !important;
  min-width: 100%;
}
.initialhw {
  .img-container-overlay {
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}
.slide-demo-box {
  height: 450px;
  border-radius: 10px;
  position: relative;
}
.guest-dropdown {
  border: 1px var(--border-color) solid !important;
  border-radius: 0 0 4px 4px;
  .guests-input_options {
    margin: 20px auto 0;
    padding: 0 45px;
    label {
      text-transform: capitalize;
      text-align: left;
      width: 100%;
      font-size: 14px;
      color: var(--gondola);
      margin: 0 0 4px;
    }
    .guest-card {
      border: 1px var(--border-color) solid;
      font-size: 13px;
      padding: 4px 15px;
    }
    .guests-input__ctrl {
      border: 0;
      padding: 5px;
      font-size: 13px;
    }
  }
  .guest-dropdown-item {
    padding: 0;
  }
}
.mob-slide-image {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.our-values {
  overflow: hidden;
}
.atmosphere-experience-await {
  .paragraph {
    p {
      margin-bottom: 0;
    }
  }
}
.ask-to-atmosphere {
  form {
    .form-submit {
      .btn {
        background-color: var(--theme-brown);
        border-color: var(--theme-brown);
        color: var(--bs-white) !important;
        &:hover,
        &:focus {
          background-color: var(--dark-overlay);
          border-color: var(--dark-overlay);
        }
      }
    }
  }
}
.pressfullview {
  a {
    color: var(--breaker-bay);
  }
}
.explore-kanifushi-plan {
  padding-left: 36px;
  padding-right: 36px;
  .hotel-heading-wrap {
    padding: 0 12px;
  }
  .rts___tabs___container {
    .rts___tabs {
      .rts___btn {
        span {
          img {
            max-width: 30px;
          }
        }
      }
    }
  }
}
.explore-kanifushi-villas {
  @extend .explore-kanifushi-plan;
}
.explore-kanifushi-plan {
  .explore-plan-tabs {
    height: 100%;
  }
  .rts___tabs___container {
    width: 440px;
    margin: 0;
    box-shadow: 10px 10px 20px 0 rgba(169, 122, 90, 0.1);
    border-radius: 10px;
    border: 1px #d9d9d9 solid;
    padding: 30px;
    flex-wrap: wrap;
    .rts___nav___btn___container {
      display: none !important;
    }
    .rts___tabs {
      padding: 0;
      width: 100%;
      display: inline;
      .rts___btn {
        border: 0;
        border-radius: 10px;
        margin: 0;
        padding: 20px 0;
        width: 100%;
        @extend .smooth;
        border: 0;
        box-shadow: none;
        background-color: transparent;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &.active {
          background-color: var(--theme-brown);
          span {
            color: var(--bs-white);
            img {
              filter: brightness(100);
            }
          }
          &:hover,
          &:focus {
            background-color: var(--theme-brown);
            span {
              color: var(--bs-white);
            }
          }
        }
        &:hover {
          background-color: var(--tab-hover-bg);
          .tab-name {
            color: var(--bs-white);
          }
          .tab-feature-img {
            img {
              filter: brightness(100);
            }
          }
        }
      }
    }
    .tab-feature-img {
      width: 85px;
      justify-content: center;
      img {
        filter: invert(59%) sepia(63%) saturate(299%) hue-rotate(144deg)
          brightness(98%) contrast(89%);
      }
    }
    .tab-name {
      color: var(--gondola);
      font-size: 15px;
    }
  }
  .rts___tabs___container ~ div {
    width: calc(100% - 440px);
  }
  .plan-tab-feature-img {
    width: 795px;
    padding: 0 25px;
    .tab-feature-image {
      overflow: hidden;
      border-radius: 10px;
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }
  .plan-tab-faqs {
    width: calc(100% - 795px);
    .accordion-header {
      button {
        text-transform: uppercase;
      }
    }
  }
}
.plan-questions,
.akira-faqs {
  box-shadow: 10px 10px 20px 0 rgba(169, 122, 90, 0.1);
  border-radius: 10px;
  border: 1px #d9d9d9 solid;
  padding: 45px 50px;
  height: 100%;
  h3 {
    letter-spacing: 2px;
    border-bottom: 1px var(--light-gray-border) solid;
    margin-bottom: 0;
    padding-bottom: 35px;
  }
  .accordion-item {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-top: 0;
    .accordion-button {
      padding: 22px 32px 22px 37px;
      @extend .georgia;
      color: var(--gondola);
      border-radius: 0;
      background-color: transparent;
      outline: 0;
      box-shadow: none;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 28px;
        width: 8px;
        height: 8px;
        background-color: var(--theme-brown);
        border-radius: 10px;
      }
      &::after {
        background-image: url("../images/plus.svg");
        background-size: 14px;
        color: var(--gondola);
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 25px;
        @extend .smooth;
      }
      &:not(.collapsed)::after {
        background-image: url("../images/minus.svg");
        background-position: 6px center;
      }
    }
    .accordion-body {
      padding-left: 37px;
      padding-top: 0;
      padding-right: 0;
      p {
        font-size: 14px;
        color: var(--gondola);
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
.explore-villas-tab {
  .villas-slides {
    .large-slides {
      height: calc(100% - 133px);
    }
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div,
    .beach-slide-card,
    .card-image {
      height: 100%;
    }
    .slick-list {
      margin-left: -10px;
      margin-right: -10px;
    }
    .thumb-slide-default {
      .slick-slide {
        padding: 13px 10px 0;
      }
    }
    .card-image {
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        aspect-ratio: 1919/1018;
      }
    }
    .thumb-slides {
      .beach-slide-card {
        height: 98px;
      }
    }
  }
}
.explore-villas {
  .beach-slide-card {
    .card-image {
      img {
        aspect-ratio: 846/449;
      }
    }
  }
}
.explore-kanifushi-villas {
  .home-villa-scrollbar {
    max-width: 385px;
    border-radius: 10px;
    .rts___tabs___container {
      width: 100%;
      min-height: 100%;
    }
  }
  .rts___tabs___container {
    width: 385px;
    margin: 0;
    background-color: var(--fountain-blue-light);
    border-radius: 10px;
    padding: 35px 25px;
    align-items: flex-start;
    border: 0;
    box-shadow: none;
    .rts___tabs {
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      align-items: flex-start;
      span {
        color: var(--gondola);
        font-size: 15px;
        white-space: initial;
        text-align: left;
      }
      .rts___btn {
        width: 100%;
        border: 0;
        background-color: transparent;
        box-shadow: none;
        margin: 0;
        padding: 19.75px 20px;
        @extend .smooth;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
  .rts___tabs___container ~ div,
  .home-villa-scrollbar ~ div {
    width: calc(100% - 385px);
    padding-left: 25px;
    height: 695px;
  }
  .villa-details {
    width: 492px;
    box-shadow: 10px 10px 20px 0 rgba(169, 122, 90, 0.1);
    border-radius: 10px;
    border: 1px #d9d9d9 solid;
    padding: 55px 50px;
    height: 100%;
  }
  .villas-slides {
    width: calc(100% - 492px);
    padding-left: 25px;
    .thumb-slides {
      border: 1px var(--gray-light-border) solid;
      border-radius: 10px;
      padding: 0 53px 5px;
      .slick-arrow {
        &.slick-prev {
          left: 10px;
        }
        &.slick-next {
          right: 10px;
        }
      }
    }
    .full-slide-view {
      transform: scale(1);
      opacity: 1;
    }
  }
  .theme-btn {
    padding: 11.5px 32px;
  }
  .dark-btn {
    background-color: var(--gondola);
    border-color: var(--gondola) !important;
    &:hover,
    &:focus {
      background-color: var(--theme-brown);
      border-color: var(--theme-brown) !important;
    }
  }
  .thumb-slide-default {
    .slick-slide {
      padding: 13px 7px 0;
      &.slick-current {
        .card-image {
          box-shadow: 0 0 8.2px 4px rgba(111, 82, 63, 0.3);
        }
      }
    }
  }
}
.villa-details {
  li {
    display: flex;
    align-items: center;
    padding: 15px 0;
    &:not(:last-child) {
      border-bottom: 1px var(--light-gray-border) solid;
    }
  }
  .info-icon {
    width: 25px;
    text-align: center;
  }
  .info-text {
    width: calc(100% - 25px);
    padding-left: 20px;
    font-size: 14px;
  }
  .villa-para {
    margin: 40px 0 10px;
    p {
      font-size: 14px;
      color: var(--gondola);
    }
  }
  .villa-slogan {
    color: var(--theme-brown);
  }
}
.sustainability {
  .btn {
    &.theme-btn {
      background-color: var(--green);
      border-color: var(--green);
      transform: none;
      &:hover,
      &:focus {
        background-color: var(--theme-brown);
        border-color: var(--theme-brown) !important;
      }
    }
  }
}
.green-sustain {
  height: 100%;
  aspect-ratio: 1454/597;
  width: 100%;
  img {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.glimpse-img {
  overflow: hidden;
  img {
    aspect-ratio: 438/354;
    width: 100%;
    @extend .smooth;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    img {
      transform: rotate(2deg) scale(1.3);
    }
  }
}
.cover-overlay {
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--dark-overlay);
    opacity: 0.5;
  }
}
.villas-slides {
  position: relative;
  .carousel {
    position: initial;
  }
  .carousel-indicators {
    border: 1px var(--gray-light-border) solid;
    margin: 0;
    padding: 0;
    top: initial;
    border-radius: 10px;
    left: 25px;
    width: calc(100% - 25px);
    padding: 13px;
    align-items: center;
    button {
      padding: 6px;
      border-radius: 14px;
      border: 1px transparent solid;
      margin: 0 2px;
      &.active {
        border-color: var(--theme-brown);
      }
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    padding: 0;
    margin: 0;
    top: initial;
    bottom: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px var(--gray-light-border) solid;
    opacity: 1;
    z-index: 3;
    .carousel-control-prev-icon {
      background-image: url(../images/prev.svg);
      background-size: 10px;
    }
    .carousel-control-next-icon {
      background-image: url(../images/next.svg);
      background-size: 10px;
    }
  }
  .carousel-control-prev {
    left: 45px;
  }
  .carousel-control-next {
    right: 20px;
  }
}
.villas-list-page {
  .villa-details {
    width: 425px;
    padding: 45px 40px;
  }
  .villas-slides {
    width: calc(100% - 425px);
  }
  .explore-villas {
    &:not(:last-child) {
      margin-bottom: 80px;
    }
    .large-slides {
      .slick-list {
        margin-left: -10px;
        margin-right: -10px;
      }
      .slick-slide {
        padding: 0 10px;
      }
    }
  }
}
.letter-spacing-2 {
  letter-spacing: 2px;
}
.slogan-two {
  font-size: 24px;
  color: var(--theme-brown);
  margin: 17px 0 0;
  display: flex;
}
.plan-villa {
  .theme-btn-outline {
    font-size: 16px;
    line-height: 1.25;
  }
}
.villa-full-details {
  .large-slides {
    width: calc(100% - 200px);
    .card-image {
      img {
        border-radius: 10px;
      }
    }
  }
  .thumb-slides {
    width: 200px;
    .slick-list {
      padding-top: 3px;
    }
    .slick-list {
      height: 100%;
    }
    .slick-track {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      height: auto !important;
    }
  }
  .large-slides {
    .slick-list {
      margin-left: -12px;
      margin-right: -12px;
    }
    .slick-slide {
      padding: 0 12px;
    }
  }
}
.villa-details-veiw {
  margin: 70px 0 0;
  h4 {
    font-size: 28px;
    span {
      padding-right: 12px;
    }
  }
}
.vill-bottom-info {
  margin: 50px 0 0;
  ul {
    width: calc(100% - 210px);
  }
  li {
    display: flex;
    align-items: center;
    color: var(--gondola);
    white-space: nowrap;
    margin-bottom: 10px;
    span {
      &:first-child {
        width: 45px;
      }
      &:last-child {
        width: calc(100% - 45px);
      }
    }
    &:not(:last-child) {
      margin-right: 75px;
    }
  }
}
.all-signatures {
  gap: 25px;
  margin: 45px 0 0;
}
.signature {
  border-radius: 10px;
  border: 1px var(--light-gray-border) solid;
  height: 145px;
  padding: 10px 25px;
  width: calc(33.333% - 16.7px);
  span {
    padding-left: 30px;
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0;
      top: 6px;
      border-radius: 10px;
      background-color: var(--theme-brown);
    }
  }
}
.all-amenities {
  .villa-amenity {
    border-radius: 20px;
    border: 1px var(--light-gray-border) solid;
    width: calc(16.66% - 20.8px);
    padding: 18px 15px 30px;
  }
  .amenity-icon {
    height: 55px;
    margin-bottom: 3px;
  }
}
.other-villa-cards {
  .slick-arrow {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 10px;
    top: 35%;
    &::before {
      content: none;
    }
    &.slick-prev {
      background-image: url(../images/prev.svg);
    }
    &.slick-next {
      background-image: url(../images/next.svg);
    }
  }
  .villa-image {
    height: 250px;
    overflow: hidden;
    border-radius: 10px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .slick-slide {
    padding: 0 17.5px;
  }
  .other-villa-text {
    h5 {
      font-size: 24px;
      height: 75px;
    }
    .theme-btn-outline {
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      padding: 11.5px 25px;
    }
  }
}
.thumb-slide-default {
  .card-image {
    border: 1px transparent solid;
    border-radius: 14px;
    height: 138px;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: 6px transparent solid;
      border-radius: 12px;
      @extend .smooth;
      overflow: hidden;
    }
    img {
      border-radius: 14px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .slick-current {
    .card-image {
      border: 1px var(--theme-brown) solid;
      &::before {
        border-color: var(--bs-white);
      }
    }
  }
  .beach-slide-card {
    text-align: center;
  }
}
.slick-arrow-default {
  .slick-arrow {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid var(--light-gray-border);
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 8px !important;
    z-index: 2;
    background-color: var(--bs-white);
    &::before {
      content: none;
    }
    &.slick-prev {
      background-image: url(../images/prev.svg);
      left: 0;
    }
    &.slick-next {
      background-image: url(../images/next.svg);
      right: 0;
    }
    &:hover,
    &:focus {
      background-color: var(--bs-white);
    }
  }
}
.villas-list-page {
  .thumb-slides {
    border: 1px var(--gray-light-border) solid;
    border-radius: 10px;
    padding: 13px 53px 5px;
    .slick-arrow {
      &.slick-prev {
        left: 10px;
      }
      &.slick-next {
        right: 10px;
      }
      &:hover,
      &:focus {
        background-color: var(--bs-white);
      }
    }
    .card-image {
      height: 105px;
    }
  }
}
.our-dining-list {
  padding-bottom: 20px;
}
.dining-card {
  margin-bottom: 80px;
  .dining-card-header {
    border-radius: 10px 10px 0 0;
    aspect-ratio: 636/271;
    img {
      width: 100%;
    }
  }
  .dining-card-body {
    border-radius: 0 0 10px 10px;
    border: 1px var(--border-color) solid;
    padding: 35px 40px;
    h5 {
      font-size: 28px;
      margin: 0 0 15px;
    }
    p {
      margin: 18px 0 22px;
      max-width: 410px;
      min-height: 70px;
    }
    .show-menu {
      font-size: 15px;
      color: var(--theme-brown);
      text-decoration: none;
      svg {
        font-size: 20px;
      }
    }
    .theme-btn-outline {
      font-size: 14px;
      font-family: Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      padding: 11.5px 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      svg {
        font-size: 22px;
      }
    }
  }
}
.dining-timing {
  min-height: 86px;
  span {
    border-radius: 50px;
    border: 1px var(--border-color) solid;
    color: var(--fiord);
    font-size: 14px;
    padding: 6px 18px;
    background-color: var(--light-bg);
    display: inline-block;
    margin-bottom: 8px;
  }
}
.dining-cat {
  padding: 14px 26px;
  border-radius: 4px;
}
.spcitypoolbar {
  .dining-cat {
    background-color: var(--tallow);
  }
}
.srilankanfood {
  .dining-cat {
    background-color: var(--light-see-green);
  }
}
.signrest {
  .dining-cat {
    background-color: var(--theme-danger);
  }
}
.specrest {
  .dining-cat {
    background-color: var(--light-brown);
  }
}
.alldaydining {
  .dining-cat {
    background-color: var(--moody-blue);
  }
}
.dining-head-info {
  .slogan-two {
    font-size: 16px;
  }
}
.dining-features {
  .row {
    &:not(:last-child) {
      padding-bottom: 90px;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .dining-feature-text {
        padding-left: 0;
        padding-right: 110px;
      }
    }
  }
}
.dining-feature-img {
  border-radius: 10px;
  aspect-ratio: 226/147;
}
.dining-feature-text {
  padding-left: 110px;
  p {
    color: var(--gondola);
  }
}
.feature-point-list {
  li {
    position: relative;
    border-bottom: 1px var(--border-color) solid;
    padding: 20px 0 20px 45px;
    &:first-child {
      border-top: 1px var(--border-color) solid;
    }
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0;
      top: 28px;
      border-radius: 10px;
      background-color: var(--theme-brown);
    }
  }
}
.restaurant-slide-card {
  padding: 0 12px;
  .restaurant-img {
    border-radius: 10px;
    aspect-ratio: 576/245;
  }
  .restaurant-text {
    h5 {
      font-size: 28px;
      word-break: break-all;
    }
    span,
    p {
      color: var(--fiord);
    }
  }
  .restaurant-action {
    a {
      color: var(--theme-brown);
      font-size: 14px;
    }
  }
}
.other-restaurants {
  .slick-arrow-default {
    .slick-arrow {
      border: 0;
      width: 13px;
      background-size: 12px !important;
      background-color: transparent;
      top: 25%;
      &.slick-prev {
        left: -45px;
      }
      &.slick-next {
        right: -45px;
      }
    }
  }
  .restaurant-img {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .dining-cat {
    padding: 6px 10px;
  }
}
.thumb-slides {
  .card-image {
    cursor: pointer;
  }
}
.akira-welcome {
  h2 {
    max-width: 850px;
  }
  .paragraph {
    max-width: 1025px;
    p {
      color: var(--gondola);
    }
  }
  .theme-btn-outline {
    font-size: 16px;
  }
}
.akira-page-wrap {
  background-image: url(../images/akira-bg.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  padding-bottom: 45px;
  .akira-text-card {
    padding-right: 105px;
    small {
      color: var(--theme-brown);
    }
    .akira-des {
      p {
        color: var(--gondola);
      }
    }
  }
  .akira-feature-image {
    border-radius: 10px;
    aspect-ratio: 159/109;
  }
  .akira-faqs {
    .accordion-item {
      background-color: transparent;
      .accordion-button {
        font-family: Arial, Helvetica, sans-serif;
        padding-left: 65px;
        &::after {
          filter: invert(59%) sepia(63%) saturate(299%) hue-rotate(144deg)
            brightness(98%) contrast(89%);
        }
        &::before {
          width: 18px;
          height: 23px;
          background-color: transparent;
          background-image: url(../images/spa-drop.svg);
          background-position: center;
          background-repeat: no-repeat;
          top: 19px;
        }
      }
      .accordion-body {
        padding-left: 65px;
      }
    }
  }
}
.akira-hightlights {
  .right-feature-wrap {
    background-color: rgba(255, 255, 255, 0.86);
    border-radius: 10px;
    box-shadow: 6px 6px 20px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(15px);
    padding: 45px 60px;
    max-width: 600px;
    .feature-text-para {
      p {
        font-size: 16px;
        color: var(--dim-gray);
        font-family: Arial, Helvetica, sans-serif;
      }
      &::after {
        content: none;
      }
    }
    .highlight-points {
      li {
        border-bottom: 1px var(--border-color) solid;
        padding: 22px 0 22px 0;
        color: var(--gondola);
        position: relative;
        span {
          padding-left: 50px;
        }
        &:first-child {
          border-top: 1px var(--border-color) solid;
        }
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 23px;
          background-color: transparent;
          background-image: url(../images/spa-drop.svg);
          background-position: center;
          background-repeat: no-repeat;
          top: 19px;
        }
      }
    }
  }
}
.akira-highlight-img {
  img {
    max-width: 815px;
    border-radius: 10px;
  }
}
.akira-services-cards {
  .row {
    margin-bottom: 90px;
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .akira-text-card {
        padding-right: 0;
        padding-left: 105px;
      }
    }
  }
}
.sustainability-page-wrap {
  background-repeat: no-repeat;
  background-position: 0 0, center bottom;
  background-size: 100%;
  background-image: url("../images/sustain-top-bg.png"),
    url("../images/sustain-bottom-bg.png");
  .learnmore {
    .theme-btn {
      background-color: var(--green);
      border-color: var(--green);
      padding: 13.5px 28px;
      transform: none;
      &:hover,
      &:focus {
        background-color: var(--theme-brown);
        border-color: var(--theme-brown);
      }
    }
  }
  .right-feature-wrap {
    padding-left: 120px;
    h4 {
      max-width: 320px;
      line-height: 1.6;
    }
    .feature-text-para {
      p {
        color: var(--dim-gray);
      }
    }
    &.right-space {
      padding-right: 120px;
    }
  }
  .green-globe-icon {
    top: 48px;
    right: -62px;
    img {
      max-width: 122px;
    }
  }
}
.welcome-sustainability {
  .theme-btn-outline {
    border-color: var(--green);
    color: var(--green);
    &:hover,
    &:focus {
      background-color: var(--green);
      border-color: var(--green);
    }
  }
  .paragraph {
    max-width: 880px;
    p {
      color: var(--gondola);
    }
  }
  h2 {
    max-width: 720px;
  }
}
.experience-tabs-view {
  .rts___tabs {
    padding: 0 0 15px;
    margin-left: -4.5px;
  }
  .rts___nav___btn___container {
    padding-bottom: 15px;
    .rts___nav___btn {
      padding: 0;
      margin: 0;
      overflow: hidden;
    }
    &:first-child {
      .rts___nav___btn {
        background-image: url("../images/prev.svg");
      }
    }
    .rts___nav___btn {
      min-width: initial !important;
      border: 0;
      width: 20px;
      background-image: url("../images/next.svg");
      background-repeat: no-repeat;
      background-position: center;
      svg {
        display: none;
      }
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
  .tab-contain {
    margin: 0 9px;
  }
  .rts___btn {
    border: 0;
    border-radius: 16px !important;
    min-width: 330px;
    width: 330px;
    background-color: transparent !important;
    box-shadow: none;
    font-size: 15px;
    padding: 7px;
    position: relative;
    margin: 0 !important;
    @extend .smooth;
    display: flex;
    &:hover,
    &:focus {
      border-color: var(--theme-brown);
      .tab-name {
        color: var(--theme-brown);
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      bottom: -9px;
      z-index: 1;
    }
    &.diving-nav {
      &:hover {
        border-color: var(--light-see-green);
        background-color: transparent;
        color: var(--light-see-green);
      }
      .gradnt-brdr,
      .tab-name {
        background-color: var(--light-see-green);
      }
      &.active {
        background-color: var(--light-see-green);
        border-color: var(--light-see-green);
        &:hover {
          color: var(--bs-white);
        }
        &::before {
          border-top-color: var(--light-see-green);
        }
      }
      .tab-feature-img {
        background-color: var(--light-see-green);
      }
    }
    &.activity-nav {
      &:hover {
        border-color: var(--moody-blue);
        background-color: transparent;
        color: var(--moody-blue);
      }
      gradnt-brdr,
      .tab-name {
        background-color: var(--moody-blue);
      }
      &.active {
        background-color: var(--moody-blue);
        border-color: var(--moody-blue);
        &:hover {
          color: var(--bs-white);
        }
        .tab-feature-img {
          img {
            filter: invert(61%) sepia(8%) saturate(3097%) hue-rotate(194deg)
              brightness(125%) contrast(91%);
          }
        }
        &::before {
          border-top-color: var(--moody-blue);
        }
      }
      .tab-feature-img {
        background-color: var(--moody-blue);
      }
    }
    &.sports-nav {
      &:hover {
        border-color: var(--fountain-blue);
        background-color: transparent;
        color: var(--fountain-blue);
      }
      &.active {
        background-color: var(--fountain-blue);
        border-color: var(--fountain-blue);
        &:hover {
          color: var(--bs-white);
        }
        .tab-feature-img {
          img {
            filter: invert(65%) sepia(98%) saturate(260%) hue-rotate(149deg)
              brightness(148%) contrast(81%);
          }
        }
        &::before {
          border-top-color: var(--fountain-blue);
        }
      }
      .tab-feature-img {
        background-color: var(--fountain-blue);
      }
    }
    &.renewal-nav {
      &:hover {
        border-color: var(--bean-red);
        background-color: transparent;
        color: var(--bean-red);
      }
      &.active {
        background-color: var(--bean-red);
        border-color: var(--bean-red);
        &:hover {
          color: var(--bs-white);
        }
        .tab-feature-img {
          img {
            filter: invert(53%) sepia(44%) saturate(4144%) hue-rotate(322deg)
              brightness(114%) contrast(112%);
          }
        }
        &::before {
          border-top-color: var(--bean-red);
        }
      }
      .tab-feature-img {
        background-color: var(--bean-red);
      }
    }
    &.repeater-nav {
      &:hover {
        border-color: var(--wisteria);
        background-color: transparent;
        color: var(--wisteria);
      }
      &.active {
        background-color: var(--wisteria);
        border-color: var(--wisteria);
        &:hover {
          color: var(--bs-white);
        }
        .tab-feature-img {
          img {
            filter: invert(24%) sepia(145%) saturate(6654%) hue-rotate(217deg)
              brightness(71%) contrast(165%);
          }
        }
        &::before {
          border-top-color: var(--wisteria);
        }
      }
      .tab-feature-img {
        background-color: var(--wisteria);
      }
    }
    .tab-feature-img {
      z-index: 1;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 10px;
      left: 10px;
    }
    img {
      width: 24px;
    }
    &.active {
      .tab-feature-img {
        background-color: var(--bs-white);
      }
    }
    .gradnt-brdr {
      padding: 1px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: "";
        background: rgb(255, 255, 255);
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.25) 32%,
          rgba(255, 255, 255, 0.75) 100%
        );
      }
    }
    .exprnc-tab-img {
      img {
        border-radius: 16px;
      }
    }
    .tab-name {
      white-space: wrap;
      border-radius: 12px;
      padding: 0 15px;
      width: 100%;
      z-index: 1;
      color: var(--bs-body-color);
    }
    &.rts___tab___selected {
      .tab-name {
        color: var(--bs-white) !important;
      }
    }
  }
}
.experience-tab-data {
  margin: 80px 0 0;
}
.experience-cards {
  margin: 80px 0 0;
  .row {
    &:nth-child(even) {
      flex-direction: row-reverse;
      margin-top: 90px;
      margin-bottom: 90px;
      .experience-card-text {
        padding-left: 0;
        padding-right: 110px;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .experience-card-img {
    border-radius: 10px;
    aspect-ratio: 636/373;
  }
  .experience-card-text {
    padding-left: 110px;
    h5 {
      font-size: 28px;
    }
  }
  .experience-card-action {
    a {
      color: var(--theme-brown);
      display: inline-block;
      line-height: 1;
      &:hover,
      &:focus {
        transform: translateX(10px);
      }
    }
  }
}
.driving-water-sports {
  h3 {
    color: var(--light-see-green);
  }
}
.activities-excursions {
  h3 {
    color: var(--moody-blue);
  }
  .experience-card-action {
    display: none !important;
  }
}
.sports-recreation {
  h3 {
    color: var(--fountain-blue);
  }
}
.renewal-of-vows {
  h3 {
    color: var(--bean-red);
  }
}
.repeater-benefits {
  h3 {
    color: var(--wisteria);
  }
}
.gallery-slide-cards {
  display: flex;
  .gallery-cover-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    visibility: visible;
    z-index: 1;
    transition-duration: 2.5s;
    img {
      object-fit: cover;
      height: 100%;
      width: auto;
    }
  }
  .gallery-slide {
    aspect-ratio: 103/70;
  }
  .gallery-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 560px;
    min-width: 88px;
    border-radius: 50px;
    cursor: pointer;
    flex: 0.5;
    justify-content: center;
    margin: 0 12px;
    position: relative;
    transition-duration: 700ms;
    &.active {
      flex: 5;
      border-radius: 30px;
      justify-content: flex-start;
      .gallery-slider {
        display: block;
      }
      .full-slide-view {
        opacity: 1;
        transform: scale(1);
      }
    }
    .gallery-slider {
      display: none;
    }
  }
  .gallery-card-caption {
    left: 13px;
    bottom: 13px;
  }
  .gallery-card-icon {
    width: 58px;
    height: 58px;
  }
  .gallery-card-text {
    width: calc(100% - 58px);
    padding-left: 18px;
  }
  .gallery-slide {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    img[src=""] {
      display: none;
    }
  }
}
.gallery-slider {
  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  }
  .slick-slide > div {
    height: 100%;
  }
  .slick-arrow {
    z-index: 2;
    border-color: var(--bs-white);
    &.slick-prev {
      left: 10px;
    }
    &.slick-next {
      right: 10px;
    }
  }
  .slick-dots {
    bottom: 32px;
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    li {
      width: auto;
      height: auto;
      margin: 0 4px;
      button {
        width: 7.5px;
        height: 7.5px;
        border-radius: 10px;
        padding: 0;
        background-color: rgba(255, 255, 255, 0.4);
        &::before {
          content: none;
        }
      }
      &.slick-active {
        button {
          background-color: var(--bs-white);
        }
      }
    }
  }
}
.full-slide-view {
  right: 22px;
  top: 22px;
  opacity: 0;
  transform: scale(0);
  .full-btn {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 11px 15px;
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  &.alternt-full-view {
    transform: scale(1);
    opacity: 1;
  }
}
.gallery-slide-modal {
  .modal-content {
    padding: 14px 14px 8px;
    border-radius: 35px;
  }
  .slick-list {
    overflow: hidden;
    border-radius: 30px;
  }
  .modal-header {
    padding: 0;
    border: 0;
  }
  .btn-close {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    box-shadow: -6px 6px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--bs-white);
    background-image: url("../images/close-dark.svg");
    background-repeat: no-repeat;
    background-position: center;
    &:hover,
    &:focus {
      background-color: var(--bs-white);
      opacity: 1;
    }
  }
  .slick-arrow {
    &.slick-prev {
      left: 15px;
    }
    &.slick-next {
      right: 15px;
    }
  }
  .slick-dots {
    display: none !important;
  }
  .gallery-slide {
    img {
      border-radius: 25px;
      width: 100%;
      aspect-ratio: 541/287;
    }
  }
  &.gallery-full-slide {
    .gallery-slide {
      img {
        aspect-ratio: auto 1472 / 945;
      }
    }
  }
}
.our-plans-tabs {
  padding-left: 36px;
  padding-right: 36px;
  .rts___btn {
    border: 1px solid var(--border-color);
    min-width: initial;
    width: initial;
    border-radius: 500px !important;
    margin: 0 9px !important;
    .tab-feature-img {
      width: 45px;
      height: 45px;
    }
    .tab-name {
      width: calc(100% - 45px);
      white-space: nowrap;
    }
  }
  .plan-cover-image {
    aspect-ratio: 1807/425;
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
  .rts___btn {
    .tab-feature-img {
      background-color: var(--theme-brown);
      img {
        height: 20px;
        width: auto;
        &[src="/static/media/airplane.e7568cf749e04da5ec4b53da8f2ebd19.svg"] {
          height: 15px;
        }
      }
      img {
        filter: brightness(100);
      }
    }
    &.active {
      background-color: var(--theme-brown) !important;
      border-color: var(--theme-brown);
      .tab-feature-img {
        background-color: var(--bs-white);
        img {
          filter: invert(59%) sepia(63%) saturate(299%) hue-rotate(144deg)
            brightness(98%) contrast(89%);
        }
      }
      &::before {
        border-top-color: var(--theme-brown);
      }
    }
    &.rts___tab___selected {
      .tab-name {
        color: var(--bs-white) !important;
      }
    }
    &:hover {
      border-color: var(--theme-brown);
      .tab-name {
        color: var(--theme-brown);
      }
    }
  }
  .rts___tabs___container {
    margin-bottom: 40px;
  }
  .tab-head-text {
    padding: 0 153px;
    top: 105px;
    h2 {
      max-width: 445px;
    }
  }
  .plans-cards {
    margin-top: -160px;
    .row {
      &:nth-child(odd) {
        .col-lg-6 {
          &:nth-child(3) {
            order: 3;
          }
        }
      }
    }
  }
  .download-brochure-btn {
    -webkit-backdrop-filter: blur(7.15px);
    backdrop-filter: blur(7.15px);
    background-color: rgba(132, 178, 193, 0.5);
    color: var(--bs-white);
    font-size: 16px;
    border-color: var(--light-brown);
    backdrop-filter: blur(7.15px);
  }
  .plan-card-text {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    border-left: 1px var(--theme-brown) solid;
    box-shadow: 6px 6px 30px 0 rgba(169, 122, 90, 0.14);
    backdrop-filter: blur(15px);
    padding: 30px 45px;
    h5 {
      font-size: 28px;
    }
    h5,
    span {
      color: var(--gondola);
    }
  }
  .plan-feature-img {
    box-shadow: 6px 6px 30px 0 rgba(169, 122, 90, 0.14);
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      aspect-ratio: 636/403;
    }
  }
  .feature-point-list {
    margin: 15px 0 0;
    li {
      padding: 8px 0 8px 37px;
      border: 0;
      &::before {
        top: 17px;
      }
      span {
        color: var(--fiord);
      }
    }
  }
  .term-condition-text {
    min-height: 25px;
    em {
      color: var(--fiord);
    }
  }
}
.morepoint {
  .btn {
    color: var(--theme-brown);
  }
}
.dining-left-side {
  .dining-timing {
    span {
      margin-bottom: 10px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.conditions-inner-wrapper {
  ul {
    padding-left: 20px;
    li {
      padding-left: 0;
      display: list-item;
      list-style-type: disc;
      color: var(--fiord);
    }
  }
}
.condition-space {
  padding-top: 40px;
}
.policy-text-color {
  color: var(--gondola);
}
.harmony-heading-new {
  max-width: 100% !important;
}
.press-releases-cards {
  h4 {
    min-height: 145px;
  }
  .card-image {
    aspect-ratio: 636/377;
  }
}
.footer-newsletter {
  .form-error {
    position: absolute;
    bottom: -20px;
    left: 0;
    margin: 0;
    line-height: 1;
    font-size: 13px;
    color: #fff !important;
    letter-spacing: 0.25px;
  }
}
.amenity-icon {
  img {
    width: 30px;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  .plantab-wrap,
  .explore-plan-tabs {
    align-items: flex-start;
  }
  .plan-tab-faqs {
    height: 100%;
  }
  .plantab-wrap {
    height: 600px;
    .rts___tabs___container {
      height: 100%;
    }
  }
  .explore-kanifushi-plan {
    .plan-tab-feature-img {
      height: 600px;
    }
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-touch-callout: none) {
    .plantab-wrap,
    .explore-plan-tabs {
      align-items: flex-start;
    }
    .plantab-wrap {
      height: 600px;
      .rts___tabs___container {
        height: 100%;
      }
    }
    .plan-tab-faqs {
      height: 100%;
    }
  }
}
.footer-newsletter {
  .form-error {
    position: absolute;
    bottom: -20px;
    left: 0;
    margin: 0;
    line-height: 1;
    font-size: 13px;
    color: #fff !important;
    letter-spacing: 0.25px;
  }
}
.term-condition-text {
  em {
    display: flex;
    &:first-child {
      width: calc(100% - 95px);
    }
    &:nth-child(2) {
      width: 105px;
      justify-content: flex-end;
    }
  }
}
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 0 15px;
}
.read-more-modal {
  max-width: 650px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  position: relative;
  backdrop-filter: blur(30px);
  margin: auto;
  padding: 30px 40px;
  border: 1px var(--medium-wood) solid;
  box-shadow: 6px 6px 30px rgba(169, 122, 90, 0.14);
  ul {
    padding: 0 0 0 15px;
    max-height: 250px;
    overflow-y: auto;
  }
  .close-icon {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    box-shadow: -6px 6px 14px rgba(0, 0, 0, 0.1);
    background-color: var(--bs-white);
    padding: 0;
    font-size: 22px;
    position: absolute;
    right: -20px;
    top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.press-text-card {
  p {
    word-break: break-all;
  }
}
.terms-condition-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 764px;
}
.conditions-inner-wrapper {
  ul {
    padding-left: 20px;
    li {
      padding-left: 0;
      display: list-item;
      list-style-type: disc;
      color: var(--fiord);
    }
  }
}
.condition-space {
  padding-top: 40px;
}
.policy-text-color {
  color: var(--gondola);
}
.scroll-bottom-gradient {
  position: relative;
  &::after {
    content: "";
    top: 227px;
    height: 75px;
    position: absolute;
    left: 0;
    background: none;
    width: 100%;
  }
}
.scroll-bottom-gradient-end {
  position: relative;
  &::after {
    content: "";
    top: 227px;
    height: 75px;
    position: absolute;
    left: 0;
    background: none;
    width: 100%;
  }
}
.akira-page-wrap {
  .theme-btn-outline {
    svg {
      position: relative;
      top: -1px;
    }
  }
}
.villa-slide-modal {
  .modal-content {
    padding-bottom: 18px;
  }
  .thumb-slides {
    display: none;
  }
}
.villa-full-details {
  .full-slide-view {
    transform: scale(1);
  }
}
.home-villas-slides {
  > .large-slides {
    .slick-arrow {
      display: none !important;
    }
  }
}
.select-label {
  select {
    appearance: none;
    background-image: url("../images/down-black-arrow.svg");
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 15px;
    padding-left: 20px;
  }
}
.value-input {
  input {
    padding: 17px 20px 17px 10px;
    &:focus {
      border-color: #86b7fe;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      outline: 0;
      color: var(--bs-body-color);
    }
  }
}
.custom-down-arrow {
  appearance: none;
  background-image: url("../images/down-black-arrow.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
}
.valid-info {
  font-size: 14px;
}
.prent-form-control {
  .value-input {
    width: calc(100% - 70px);
  }
}
.heading-icon img {
  height: 60px;
}
.award-text {
  padding: 0 55px;
}
.award-by {
  min-height: 50px;
  font-weight: 700;
}
.page-not-found {
  padding: 230px 0;
  .error-text {
    .error-small-text {
      color: var(--theme-brown);
    }
    h2 {
      font-size: 50px;
    }
  }
}
.page-note-found-header {
  background-color: var(--gondola);
}
.custom-container {
  max-width: 1830px;
  margin-left: auto;
  margin-right: auto;
}
.explore-kanifushi-plan {
  .plan-tab-feature-img {
    .tab-feature-image {
      img {
        aspect-ratio: 1163/873;
      }
    }
  }
}
.kanifushi-glimses {
  .row {
    .col-lg-4 {
      .glimpse-img {
        &:first-child {
          margin-bottom: 0.25rem;
        }
      }
    }
  }
  .g-1 {
    --bs-gutter-y: 0.25rem;
    --bs-gutter-x: 0.25rem;
  }
  .mb-one {
    margin-bottom: 0.2rem;
  }
}
.first-glimses-col {
  .glimpse-img {
    &:first-child {
      border-radius: 10px 0 0 0;
    }
    border-radius: 0 0 0 10px;
  }
}
.right-glimses-col {
  .glimpse-img {
    &:first-child {
      border-radius: 0 10px 0 0;
    }
    &:last-child {
      .glimpse-img {
        border-radius: 0 10px 0 0;
      }
    }
    border-radius: 0 0 10px 0;
  }
}
.award-image {
  img {
    aspect-ratio: 49/38;
  }
}
.home-explore-plan {
  .rts___tabs___container,
  .plan-questions {
    background-color: var(--fountain-blue-light);
    border: 0;
    box-shadow: none;
  }
  .accordion-item {
    background-color: transparent;
    border-color: var(--accordion-border-color);
  }
  .plan-questions {
    h3 {
      border-color: var(--accordion-border-color);
    }
  }
}
.home-villas-explore {
  .tab-villa-icon {
    width: 32px;
    height: 32px;
    transition: all 0.4s ease-in-out 0s;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 0;
  }
  .tab-name {
    width: calc(100% - 32px);
    padding-left: 12px;
  }
  .rts___tabs___container {
    .rts___tabs {
      .rts___btn {
        &.active,
        &:hover {
          .tab-villa-icon {
            background-size: 100%;
            background-image: url("../images/thumb-circle-logo.svg");
          }
        }
        &:not(:last-child) {
          margin-bottom: 7px;
        }
      }
    }
  }
}
.glimpse-lightbox {
  width: 33.333%;
  padding: 2.5px;
  &:first-child {
    img {
      border-radius: 10px 0 0 0;
    }
  }
  &:nth-child(3) {
    img {
      border-radius: 0 10px 0 0;
    }
  }
  &:nth-child(4) {
    img {
      border-radius: 0 0 0 10px;
    }
  }
  &:last-child {
    img {
      border-radius: 0 0 10px 0;
    }
  }
}
.kanifushi-glimses {
  .glimpse-img {
    overflow: hidden;
  }
  .glimpse-left {
    .glimpse-img {
      &:first-child {
        border-radius: 10px 0 0 0;
      }
      &:last-child {
        border-radius: 0 0 0 10px;
      }
    }
  }
  .glimpse-last {
    .glimpse-img {
      &:first-child {
        border-radius: 0 10px 0 0;
      }
      &:last-child {
        border-radius: 0 0 10px 0;
      }
    }
  }
}
.glimpmses-full-view {
  .gallery-slide {
    img {
      aspect-ratio: 438/354;
    }
  }
}
.glimpmses-full-view {
  .modal-dialog {
    max-width: 700px;
  }
}
.home-accordion-text {
  div {
    > div {
      &:nth-child(1) {
        padding-bottom: 16px;
      }
    }
  }
}
.planscroll-custm {
  > div {
    overflow-x: hidden !important;
  }
}
.dinning-drpdwn {
  .theme-btn-outline {
    background-color: transparent;
    svg {
      transition: all 250ms ease-in-out 0s;
    }
    &.show {
      svg {
        transform: rotate(90deg);
      }
    }

    &.showMenu {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.remove-drop {
  &::after {
    content: none;
  }
}
.getting-ready {
  min-height: 100vh;
  overflow: auto;
  padding-left: 95px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/atmosphere-bg.jpg);
}
.getting-ready-inner {
  min-height: 100vh;
  padding: 70px 90px 100px;
  width: 100%;
  max-width: 718px;
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}
.getting-ready-content {
  margin-top: 159px;
}
.getting-ready-content h1 {
  font-size: 40px;
  color: #333333;
  font-family: Georgia;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.5px;
}
.getting-ready-content p {
  font-size: 18px;
  max-width: 534px;
  color: #333333;
  font-family: arial;
  line-height: 1.5;
  letter-spacing: 0.3px;
}
.getting-ready-content .book-now-btn {
  padding: 17px 36px;
  color: white;
  font-size: 14px;
  display: inline-block;
  border-radius: 4px;
  margin-top: 40px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #6e9a95;
  transition: all 500ms ease-in-out 0s;
}
.getting-ready-content .book-now-btn:hover {
  background-color: #031c2d;
  color: #ffffff;
}
.getting-ready-content .book-now-btn:focus {
  background-color: #031c2d;
  color: #ffffff;
}
.raaya-getting-ready .getting-ready-content .book-now-btn {
  background-color: #549dae;
  color: #ffffff;
}
.raaya-getting-ready .getting-ready-content .book-now-btn:hover {
  background-color: #202020;
  color: #ffffff;
}
.raaya-getting-ready .getting-ready-content .book-now-btn:focus {
  background-color: #202020;
  color: #ffffff;
}
.footer-info {
  height: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 80px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.footer-info.varu-raaya {
  background-color: #549dae;
}
.footer-info a {
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
}
.footer-info span {
  margin-right: 8px;
}
.three-d-btn img {
  @extend .smooth;
}
.three-d-btn:hover img,
.three-d-btn:focus img {
  filter: brightness(100);
}
.show-menu svg {
  vertical-align: text-bottom;
}
.arrow-hide {
  color: var(--bs-body-color) !important;
  &::before {
    content: none !important;
  }
}
.guest-backdrop {
  filter: blur(0);
}
.guestmodal-open-body {
  * {
    filter: blur(0) !important;
  }
  .guestmodal {
    --bs-modal-width: 100%;
    .modal-content {
      border-radius: 0;
      border: 0;

      background: #f0f0f0;
    }
    &.show {
      display: flex !important;
      align-items: flex-end;
    }
    .modal-dialog {
      width: 100%;
      margin: 0;
    }
    .guest-dropdown {
      border: 0 !important;
    }
  }
}
.new-exprnc-tabs {
  .rts___tabs___container {
    gap: 25px;
  }
}
.tabs-container {
  max-width: 1425px;
  .rts___tabs___container {
    max-width: 100%;
  }
}
.input-number {
  user-select: none;
}

.atmosphere-book-now {
  .parent {
    &.highlight {
      // height: 350px;
    }
  }
  .daterangepicker {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    border: 0 !important;
    margin-top: 0 !important;
    &.show-calendar {
      border: 1px solid #ddd !important;
      margin-top: 7px !important;
      &.opensright {
        &::before,
        &::after {
          display: flex !important;
        }
      }
    }
    &.opensright {
      &::before,
      &::after {
        display: none !important;
      }
    }
  }
  .datepicker-open ~ .daterangepicker {
    display: flex !important;
    width: -moz-fit-content;
    width: fit-content;
  }
}
.ahr-guests-save-btn {
  display: flex;
  justify-content: center;
  padding: 8px 8px 24px;
  button {
    width: auto;
    padding: 6.5px 25px !important;
    &::before {
      background-image: none !important;
    }
  }
}

.guestmodal-open-body {
  .guest-dropdown {
    width: 100%;
  }
}

.dinning-drpdwn {
  .dropdown-item {
    border-bottom: 1px #efefef solid;
    color: #556271;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

    &:active {
      background: #f8f9fa;
    }
    .arrowbtn {
      transform: scale(0);
      min-width: 15px;
    }
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      .arrowbtn {
        transform: scale(1);
      }
    }
  }
}

.dh-inner {
  .theme-btn-outline {
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    svg {
      transform: rotate(90deg);
      font-size: 20px;
    }
  }
  .show-menuu {
    font-size: 15px;
    color: var(--theme-brown);
    text-decoration: none;
    svg {
      font-size: 20px;
    }
  }
  .singleMenu{
    svg {
      transform: rotate(0deg);
      font-size: 20px;
    }
  }
}
